import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import { absoluteURL } from 'pkg/url';
import { isApp } from 'pkg/platform';

import { FormDataValue, FormPayload } from 'components/form/Form';

import * as flashes from './flashes';

interface Payload {
	form: models.form.Form;
	data: FormPayload;
	organizationId: number;

	discount?: models.discount.Discount;
}

interface PriceData {
	productPriceId: number;
	qty: number;
	taxRateId?: number;
	validTo?: number;
}

const getPriceData = (
	productQuantities: FormDataValue,
	formProductPrices: models.form.FormProductPrice[]
): PriceData[] => {
	return Object.entries(productQuantities).map(([key, value]) => {
		// productQuantities == {[productId_*id*]: value}
		const productId = key.split('_')[1];

		const formProductPrice = formProductPrices.find(
			(spp) => spp.productPrice.productId === Number.parseInt(productId, 10)
		);

		return {
			productPriceId: formProductPrice.productPriceId,
			qty: Number.parseInt(value as string, 10),
			taxRateId: formProductPrice.taxRateId,
			validTo: formProductPrice.validTo,
		};
	});
};

interface CreatePayload {
	fields: { [key: string]: FormDataValue };
	bundleId?: number;
	prices?: PriceData[];
	discountId?: number;
	submittedForAccountId: number;
	successUrl: string;
	cancelUrl: string;
	useCheckoutService: boolean;
}

export const create = async ({ form, data, discount }: Payload) => {
	const {
		product_quantities,
		selected_bundle,
		submittedForAccountId,
		...fields
	} = data;

	const payload: CreatePayload = {
		fields,
		submittedForAccountId: Number.parseInt(submittedForAccountId as string, 10),
		useCheckoutService: isApp(),
		successUrl: absoluteURL(routes.Registration.PaymentStatus(form.guid)),
		cancelUrl: absoluteURL(routes.Registration.PaymentStatus(form.guid)),
	};

	if (product_quantities) {
		const bundleId = Number.parseInt(selected_bundle as string, 10);
		const selectedFormProductPrices = form.productPrices.filter(
			(pp) => pp.bundleNumber === bundleId
		);

		const prices = getPriceData(product_quantities, selectedFormProductPrices);

		payload.prices = prices;
		payload.bundleId = bundleId;
	}

	if (discount) {
		payload.discountId = discount.id;
	}

	const request = await sdk.post(
		endpoints.FormSubmissions.Create(form.guid),
		{},
		payload
	);

	if (!request.ok) {
		flashes.show({
			title: t('Failed to submit form submission'),
		});
	}

	return request;
};
