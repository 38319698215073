import { Fragment } from 'react';
import { t } from '@transifex/native';

import { Features } from 'pkg/models/group';

import { usePaymentProviderContext } from 'pkg/contexts/provider_settings';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import { useUser } from 'pkg/hooks/selectors';

import UnreadChatMessagesBadge from 'containers/chat/UnreadChatMessagesBadge';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Avatar from 'components/avatar';

import { NavigationItemsProps } from 'components/navigation/menus/NavigationItems';
import GroupLinkFolders from 'components/navigation/menus/group_link_folders';

import * as Drawer from 'design/navigation/drawer';

const _context = 'navigation';

function TeamNavigation({
	displayChildren,
	handleDisplayChildren,
}: NavigationItemsProps): JSX.Element {
	const membership = useCurrentMembership();
	const org = useCurrentOrganization();
	const group = useCurrentGroup();
	const sport = group.sport;
	const targetUser = useUser(membership.targetUserId);
	const targetUserFirstName = targetUser.firstName;
	const targetUserLastName = targetUser.lastName;

	const isParent = models.membership.isParent(membership);
	const isAdmin = !isParent && models.membership.isAdmin(membership);
	const isAdminOrStaff =
		!isParent && models.membership.isAdminOrStaff(membership);
	const isPlayer = !isParent && models.membership.isPlayer(membership);
	const paymentProvider = usePaymentProviderContext();
	const groupHasCustomLinksEnabled =
		group && models.group.hasFeature(group, models.group.Features.CustomLinks);

	return (
		<Fragment>
			{isAdminOrStaff && (
				<Fragment>
					<LargeScreen>
						<Drawer.ItemGroup>
							<Drawer.Item
								href={routes.Organization.Home(org.id)}
								name={t('Home')}
								icon="home"
							/>
							<Drawer.Item
								href={routes.Calendar.Index(
									org.id,
									membership.groupId,
									sessionStorage.getItem('currentMode') || 'month'
								)}
								name={t('Calendar')}
								icon="calendar_today"
								testid="navigation.calendar"
								show={models.group.hasFeature(group, Features.Calendar)}
							/>
							<Drawer.Item
								href={routes.Chat.Index(org.id)}
								name={t('Chat')}
								icon="forum"
								testid="navigation.chat"
								show={models.group.hasFeature(group, Features.Chat)}>
								<UnreadChatMessagesBadge inDrawer />
							</Drawer.Item>
						</Drawer.ItemGroup>
					</LargeScreen>
					<Drawer.ItemGroup
						show={
							models.group.hasAnyFeature(
								group,
								Features.Chat,
								Features.Posts
							) || models.group.hasFeature(group, Features.Forms)
						}>
						<SmallScreen>
							<Drawer.Item
								href={routes.Group.Wall(org.id, group.id)}
								name={t('Wall', { _context })}
								icon="feed"
								show={models.group.hasFeature(group, Features.Posts)}
							/>
						</SmallScreen>
						<LargeScreen>
							<Drawer.Item
								href={routes.Group.Show(org.id, membership.groupId)}
								name={t('Team')}
								icon="groups"
							/>
						</LargeScreen>
						<Drawer.ItemGroupCollapsible
							title={t('Payments')}
							slug="payments"
							expand={displayChildren}
							onClick={handleDisplayChildren}
							icon="monetization_on"
							show={
								models.membership.hasCapability(
									membership,
									models.membership.Capabilities.PaymentAdmin
								) &&
								models.group.hasFeature(group, Features.Payments) &&
								models.group.hasActivePaymentProvider(group)
							}>
							<Drawer.Item
								href={routes.Payments.Overview(org.id)}
								name={t('Overview')}
							/>
							<Drawer.Item
								href={routes.Payments.Orders(org.id)}
								name={t('Invoices')}
							/>
							<Drawer.Item
								href={routes.Payments.Subscriptions(org.id)}
								name={t('Subscriptions')}
							/>
							<Drawer.Item
								href={routes.Products.Index(org.id)}
								name={t('Products')}
							/>
							<Drawer.Item
								href={routes.Payments.Payouts(org.id)}
								name={t('Payouts')}
							/>
							<Drawer.Item
								href={routes.Discounts.Index(org.id)}
								name={t('Discounts')}
								show={paymentProvider.settings.canHaveDiscountCode}
							/>
						</Drawer.ItemGroupCollapsible>
						<Drawer.Item
							href={routes.Registrations.Index(
								org.id,
								membership.groupId,
								'active'
							)}
							icon="contact_page"
							name={t('Registrations')}
							testid="navigation.forms"
							show={
								models.membership.hasCapability(
									membership,
									models.membership.Capabilities.Forms
								) && models.group.hasFeature(group, Features.Forms)
							}
						/>
						<Drawer.ItemGroupCollapsible
							title={t('Scheduling')}
							slug="scheduling"
							expand={displayChildren}
							onClick={handleDisplayChildren}
							icon="schedule"
							show={
								models.membership.hasCapability(
									membership,
									models.membership.Capabilities.Scheduler
								) && models.group.hasFeature(group, Features.Scheduling)
							}>
							<Drawer.Item
								href={routes.Bookings.Index(org.id, membership.groupId)}
								name={t('Bookings')}
							/>
							<Drawer.Item
								href={routes.Templates.Index(org.id, membership.groupId)}
								name={t('Templates')}
							/>
							<Drawer.Item
								href={routes.Resources.Index(
									org.id,
									membership.groupId,
									'overview'
								)}
								name={t('Resources')}
							/>
						</Drawer.ItemGroupCollapsible>
						<Drawer.Item
							href={routes.Group.Post.Index(
								org.id,
								membership.groupId,
								'published'
							)}
							name={t('Posts')}
							icon="dynamic_feed"
							show={models.group.hasFeature(group, Features.Posts)}
						/>

						<Drawer.ItemGroupCollapsible
							title={t('Development')}
							slug="development"
							expand={displayChildren}
							onClick={handleDisplayChildren}
							icon="tactic"
							show={
								(models.sport.hasRatingsEnabled(sport) &&
									models.group.hasFeature(group, Features.Assessments)) ||
								models.group.hasAnyFeature(
									group,
									Features.Video,
									Features.TrainingLibrary,
									Features.IndividualDevelopment
								)
							}>
							<Drawer.Item
								href={routes.TrainingLibrary.Index(org.id, membership.groupId)}
								name={t('Training Library')}
								show={models.group.hasFeature(group, Features.TrainingLibrary)}
							/>
							<Drawer.Item
								href={routes.VideoLibrary.Index(org.id, membership.groupId)}
								name={t('Video')}
								testid="navigation.video_library"
								show={models.group.hasFeature(group, Features.Video)}
							/>
							<Drawer.Item
								href={routes.Development.Formations.Index(
									org.id,
									membership.groupId
								)}
								name={t('Formations')}
								show={
									models.sport.hasRatingsEnabled(sport) &&
									models.group.hasFeature(group, Features.Assessments)
								}
							/>
							<Drawer.Item
								href={routes.Organization.Development.Assessments(
									org.id,
									membership.groupId
								)}
								name={t('Assessments')}
								show={
									models.sport.hasRatingsEnabled(sport) &&
									models.group.hasFeature(group, Features.Assessments)
								}
							/>
							<Drawer.Item
								href={routes.Organization.Development.PerformanceReviews(
									org.id,
									membership.groupId
								)}
								name={t('Performance reviews')}
								show={models.group.hasFeature(
									group,
									Features.IndividualDevelopment
								)}
							/>
						</Drawer.ItemGroupCollapsible>

						<Drawer.ItemGroupCollapsible
							title={t('Statistics')}
							icon="bar_chart"
							slug="statistics"
							expand={displayChildren}
							onClick={handleDisplayChildren}>
							{models.sport.hasMatchesEnabled(sport) && (
								<Drawer.Item
									href={routes.Statistics.Match(org.id, membership.groupId)}
									name={t('Match')}
									show={models.group.hasFeature(
										group,
										Features.AggregatedStatistics
									)}
								/>
							)}
							<Drawer.Item
								href={routes.Statistics.Training(org.id, membership.groupId)}
								name={t('Training')}
								show={models.group.hasFeature(
									group,
									Features.AggregatedStatistics
								)}
							/>
							<Drawer.Item
								href={routes.Statistics.AttendanceReport.Show(
									org.id,
									membership.groupId,
									'overview'
								)}
								name={t('Attendance')}
							/>
						</Drawer.ItemGroupCollapsible>
						<Drawer.ItemGroupCollapsible
							show={isAdmin}
							title={t('Group settings')}
							slug="settings"
							expand={displayChildren}
							onClick={handleDisplayChildren}
							icon="settings">
							<Drawer.Item
								href={routes.Settings.Index(
									org.id,
									membership.groupId,
									'general'
								)}
								name={t('General')}
							/>
							<Drawer.Item
								href={routes.Settings.Users(org.id, membership.groupId, 'all')}
								name={t('Users')}
							/>
							<Drawer.Item
								href={routes.Settings.Organization(org.id, membership.groupId)}
								name={t('Organization')}
							/>
							<Drawer.Item
								href={routes.Settings.Competitions(org.id, membership.groupId)}
								name={t('Competitions')}
							/>
							{group.country?.code === 'SE' && (
								<Drawer.Item
									href={routes.Settings.Index(
										org.id,
										membership.groupId,
										'import'
									)}
									name={t('Imports (Svff)')}
								/>
							)}
						</Drawer.ItemGroupCollapsible>
					</Drawer.ItemGroup>
				</Fragment>
			)}

			{isPlayer && (
				<Fragment>
					<LargeScreen>
						<Drawer.ItemGroup>
							<Drawer.Item
								href={routes.Organization.Home(org.id)}
								name={t('Home')}
								icon="home"
							/>
							<Drawer.Item
								href={routes.Calendar.Index(
									org.id,
									membership.groupId,
									sessionStorage.getItem('currentMode') || 'month'
								)}
								name={t('Calendar')}
								icon="calendar_today"
								testid="navigation.calendar"
								show={models.group.hasFeature(group, Features.Calendar)}
							/>
							<Drawer.Item
								href={routes.Chat.Index(org.id)}
								name={t('Chat')}
								icon="forum"
								testid="navigation.chat"
								show={models.group.hasFeature(group, Features.Chat)}>
								<UnreadChatMessagesBadge inDrawer />
							</Drawer.Item>
						</Drawer.ItemGroup>
					</LargeScreen>
					<SmallScreen></SmallScreen>
					<Drawer.ItemGroup>
						<SmallScreen>
							<Drawer.Item
								href={routes.Group.Wall(org.id, group.id)}
								icon="feed"
								name={t('Wall', { _context })}
								show={models.group.hasFeature(group, Features.Posts)}
							/>
						</SmallScreen>
						<LargeScreen>
							<Drawer.Item
								href={routes.Group.Show(org.id, membership.groupId)}
								name={t('Team')}
								icon="groups"
							/>
						</LargeScreen>
						<Drawer.ItemGroupCollapsible
							title={t('Payments')}
							slug="payments"
							expand={displayChildren}
							onClick={handleDisplayChildren}
							icon="monetization_on"
							show={
								models.membership.hasCapability(
									membership,
									models.membership.Capabilities.PaymentAdmin
								) &&
								models.group.hasFeature(group, Features.Payments) &&
								models.group.hasActivePaymentProvider(group)
							}>
							<Drawer.Item
								href={routes.Payments.Overview(org.id)}
								name={t('Overview')}
							/>
							<Drawer.Item
								href={routes.Payments.Orders(org.id)}
								name={t('Invoices')}
							/>
							<Drawer.Item
								href={routes.Payments.Subscriptions(org.id)}
								name={t('Subscriptions')}
							/>
							<Drawer.Item
								href={routes.Products.Index(org.id)}
								name={t('Products')}
							/>
							<Drawer.Item
								href={routes.Payments.Payouts(org.id)}
								name={t('Payouts')}
							/>
							<Drawer.Item
								href={routes.Discounts.Index(org.id)}
								name={t('Discounts')}
								show={paymentProvider.settings.canHaveDiscountCode}
							/>
						</Drawer.ItemGroupCollapsible>
						<Drawer.ItemGroupCollapsible
							title={t('Scheduling')}
							slug="scheduling"
							expand={displayChildren}
							onClick={handleDisplayChildren}
							icon="schedule"
							show={
								models.membership.hasCapability(
									membership,
									models.membership.Capabilities.Scheduler
								) && models.group.hasFeature(group, Features.Scheduling)
							}>
							<Drawer.Item
								href={routes.Bookings.Index(org.id, membership.groupId)}
								name={t('Bookings')}
							/>
							<Drawer.Item
								href={routes.Templates.Index(org.id, membership.groupId)}
								name={t('Templates')}
							/>
							<Drawer.Item
								href={routes.Resources.Index(
									org.id,
									membership.groupId,
									'overview'
								)}
								name={t('Resources')}
							/>
						</Drawer.ItemGroupCollapsible>
						<Drawer.Item
							href={routes.VideoLibrary.Index(org.id, membership.groupId)}
							name={t('Video')}
							icon="smart_display"
							testid="navigation.video_library"
							show={models.group.hasFeature(group, Features.Video)}
						/>
						<Drawer.ItemGroupCollapsible
							title={t('Statistics')}
							icon="bar_chart"
							slug="statistics"
							expand={displayChildren}
							onClick={handleDisplayChildren}>
							{models.sport.hasMatchesEnabled(sport) && (
								<Drawer.Item
									href={routes.Statistics.Match(org.id, membership.groupId)}
									name={t('Match')}
									show={models.group.hasFeature(
										group,
										Features.AggregatedStatistics
									)}
								/>
							)}
							<Drawer.Item
								href={routes.Statistics.Training(org.id, membership.groupId)}
								name={t('Training')}
								show={models.group.hasFeature(
									group,
									Features.AggregatedStatistics
								)}
							/>
						</Drawer.ItemGroupCollapsible>
						{models.membership.hasCapability(
							membership,
							models.membership.Capabilities.Forms
						) && models.group.hasFeature(group, Features.Forms) ? (
							<Drawer.Item
								href={routes.Registrations.Index(
									org.id,
									membership.groupId,
									'active'
								)}
								icon="contact_page"
								name={t('Registrations')}
								testid="navigation.forms"
							/>
						) : (
							<Drawer.Item
								href={routes.User.Registrations(org.id, 'open')}
								name={t('Registrations')}
								icon="contact_page"
							/>
						)}
					</Drawer.ItemGroup>
				</Fragment>
			)}
			<LargeScreen>
				<Drawer.ItemGroup show={isParent}>
					<Drawer.Item
						href={routes.Organization.Home(org.id)}
						name={t('Home')}
						icon="home"
					/>
					<Drawer.Item
						href={routes.Calendar.Index(
							org.id,
							membership.groupId,
							sessionStorage.getItem('currentMode') || 'month'
						)}
						name={t('Calendar')}
						icon="calendar_today"
						testid="navigation.calendar"
						show={models.group.hasFeature(group, Features.Calendar)}
					/>
					<Drawer.Item
						href={routes.Chat.Index(org.id)}
						name={t('Chat')}
						icon="forum"
						testid="navigation.chat"
						show={models.group.hasFeature(group, Features.Chat)}>
						<UnreadChatMessagesBadge inDrawer />
					</Drawer.Item>
				</Drawer.ItemGroup>
			</LargeScreen>
			<Drawer.ItemGroup
				show={isParent}
				title={`${targetUserFirstName} ${targetUserLastName}`}>
				<Drawer.Item
					href={routes.User.Profile.Show(
						org.id,
						membership.groupId,
						membership.targetUserId,
						'overview'
					)}
					avatar={<Avatar size={18} user={targetUser} />}
					name={t('Profile')}
				/>
				<SmallScreen>
					<Drawer.Item
						href={routes.Group.Wall(org.id, group.id)}
						name={t('Wall', { _context })}
						icon="feed"
						show={models.group.hasFeature(group, Features.Posts)}
					/>
				</SmallScreen>
				<LargeScreen>
					<Drawer.Item
						href={routes.Group.Show(org.id, membership.groupId)}
						name={t('Team')}
						icon="groups"
					/>
				</LargeScreen>
				<Drawer.ItemGroupCollapsible
					title={t('Payments')}
					slug="payments"
					expand={displayChildren}
					onClick={handleDisplayChildren}
					icon="monetization_on"
					show={
						models.membership.hasCapability(
							membership,
							models.membership.Capabilities.PaymentAdmin
						) &&
						models.group.hasFeature(group, Features.Payments) &&
						models.group.hasActivePaymentProvider(group)
					}>
					<Drawer.Item
						href={routes.Payments.Overview(org.id)}
						name={t('Overview')}
					/>
					<Drawer.Item
						href={routes.Payments.Orders(org.id)}
						name={t('Invoices')}
					/>
					<Drawer.Item
						href={routes.Payments.Subscriptions(org.id)}
						name={t('Subscriptions')}
					/>
					<Drawer.Item
						href={routes.Products.Index(org.id)}
						name={t('Products')}
					/>
					<Drawer.Item
						href={routes.Payments.Payouts(org.id)}
						name={t('Payouts')}
					/>
					<Drawer.Item
						href={routes.Discounts.Index(org.id)}
						name={t('Discounts')}
						show={paymentProvider.settings.canHaveDiscountCode}
					/>
				</Drawer.ItemGroupCollapsible>
				<Drawer.ItemGroupCollapsible
					title={t('Scheduling')}
					slug="scheduling"
					expand={displayChildren}
					onClick={handleDisplayChildren}
					icon="schedule"
					show={
						models.membership.hasCapability(
							membership,
							models.membership.Capabilities.Scheduler
						) && models.group.hasFeature(group, Features.Scheduling)
					}>
					<Drawer.Item
						href={routes.Bookings.Index(org.id, membership.groupId)}
						name={t('Bookings')}
					/>
					<Drawer.Item
						href={routes.Templates.Index(org.id, membership.groupId)}
						name={t('Templates')}
					/>
					<Drawer.Item
						href={routes.Resources.Index(
							org.id,
							membership.groupId,
							'overview'
						)}
						name={t('Resources')}
					/>
				</Drawer.ItemGroupCollapsible>
				<Drawer.Item
					href={routes.VideoLibrary.Show(org.id, membership.groupId, 'team')}
					name={t('Video')}
					icon="videocam"
					testid="navigation.video_library"
					show={models.group.hasFeature(group, Features.Video)}
				/>
				{models.membership.hasCapability(
					membership,
					models.membership.Capabilities.Forms
				) && models.group.hasFeature(group, Features.Forms) ? (
					<Drawer.Item
						href={routes.Registrations.Index(
							org.id,
							membership.groupId,
							'active'
						)}
						icon="contact_page"
						name={t('Registrations')}
						testid="navigation.forms"
					/>
				) : (
					<Drawer.Item
						href={routes.User.Registrations(org.id, 'open')}
						name={t('Registrations')}
						icon="contact_page"
					/>
				)}
			</Drawer.ItemGroup>
			<SmallScreen>
				<Drawer.ItemGroup show={isParent}>
					<Drawer.Item
						href={routes.User.Registrations(org.id, 'open')}
						name={t('Registrations')}
						icon="contact_page"
					/>
				</Drawer.ItemGroup>
			</SmallScreen>

			{groupHasCustomLinksEnabled && (
				<GroupLinkFolders
					displayChildren={displayChildren}
					handleDisplayChildren={handleDisplayChildren}
				/>
			)}
		</Fragment>
	);
}

export default TeamNavigation;
