// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JBc1IxtwHnlNGsa8_aQq {
	font-weight: var(--font-weight-semibold);
	font-size: var(--font-size-lg);
}

.vJZLd1KVvd3SFUQYzz12 {
	background-color: var(--palette-green-200);
	padding: var(--spacing-2) var(--spacing-3);
	font-size: var(--font-size-sm);
	color: var(--palette-gray-900);
	border-radius: var(--radius-2);
}

.ImhGJWwSFTsUY0DckKVB {
	color: var(--palette-gray-500);
	line-height: var(--font-line-height-lg);
}

.WaP7UUUXeg5xGeAoLlcQ {
	color: var(--palette-gray-900);
	font-weight: var(--font-weight-semibold);
}

.OFDzb3JZBSyRyqHPynhe {
	position: sticky;
	top: calc(var(--action-bar-height-standard) + var(--spacing-4));
	padding: var(--spacing-6);
}
`, "",{"version":3,"sources":["webpack://./routes/payments/discounts/preview/styles.css"],"names":[],"mappings":"AAAA;CACC,wCAAwC;CACxC,8BAA8B;AAC/B;;AAEA;CACC,0CAA0C;CAC1C,0CAA0C;CAC1C,8BAA8B;CAC9B,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;CAC9B,uCAAuC;AACxC;;AAEA;CACC,8BAA8B;CAC9B,wCAAwC;AACzC;;AAEA;CACC,gBAAgB;CAChB,+DAA+D;CAC/D,yBAAyB;AAC1B","sourcesContent":[".summary {\n\tfont-weight: var(--font-weight-semibold);\n\tfont-size: var(--font-size-lg);\n}\n\n.code {\n\tbackground-color: var(--palette-green-200);\n\tpadding: var(--spacing-2) var(--spacing-3);\n\tfont-size: var(--font-size-sm);\n\tcolor: var(--palette-gray-900);\n\tborder-radius: var(--radius-2);\n}\n\n.textRow {\n\tcolor: var(--palette-gray-500);\n\tline-height: var(--font-line-height-lg);\n}\n\n.textRowValue {\n\tcolor: var(--palette-gray-900);\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.previewBox {\n\tposition: sticky;\n\ttop: calc(var(--action-bar-height-standard) + var(--spacing-4));\n\tpadding: var(--spacing-6);\n}\n"],"sourceRoot":""}]);
// Exports
export var summary = `JBc1IxtwHnlNGsa8_aQq`;
export var code = `vJZLd1KVvd3SFUQYzz12`;
export var textRow = `ImhGJWwSFTsUY0DckKVB`;
export var textRowValue = `WaP7UUUXeg5xGeAoLlcQ`;
export var previewBox = `OFDzb3JZBSyRyqHPynhe`;
export default ___CSS_LOADER_EXPORT___;
