import { Options, RRule, rrulestr } from 'rrule';
import { useMediaQuery } from 'react-responsive';
import { useState } from 'react';
import { useT } from '@transifex/react';
import { FieldErrors, FieldValues, SubmitErrorHandler } from 'react-hook-form';

import { PageWidths } from 'pkg/config/sizes';
import * as styles from 'pkg/config/styles';
import { toMedium } from 'pkg/config/breakpoints';

import * as flashActions from 'pkg/actions/flashes';
import { setForceNavBarBackLink } from 'pkg/actions/app';

import { popState } from 'pkg/router/state';
import * as actions from 'pkg/actions';
import useMixedState, { MixedStateSetter } from 'pkg/hooks/useMixedState';
import DateTime, { Granularity } from 'pkg/datetime';
import * as routes from 'pkg/router/routes';
import {
	getGmtStringByTimeZoneName,
	getTimeZoneByGmtString,
	getTimeZoneFromSessionStorage,
} from 'pkg/timezone';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import store from 'pkg/store/createStore';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { EventSeriesType } from 'pkg/api/models/event_series';
import { useCurrentOrganization } from 'pkg/identity';
import { RSVPInterval } from 'pkg/api/models/event';

import EditForm from 'routes/event/series/edit/Form';
import { IEditSeriesFormState } from 'routes/event/interfaces';
import * as css from 'routes/event/single/styles.css';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import { Spinner } from 'components/loaders/spinner';
import * as ActionBar from 'components/layout/ActionBar';
import Form from 'components/form/Form';
import useAttachments from 'components/attachment/hooks/useAttachments';
import Column from 'components/layout/column';
import Row from 'components/layout/row';
import * as Input from 'components/form/inputs';

import { useDialog } from 'design/dialog';
import Button from 'design/button';

interface EventEditWrapperProps {
	eventSeriesId: number;
	setPageTitle: (title: string) => void;
}

export default function EventEditWrapper({
	eventSeriesId,
	setPageTitle,
}: EventEditWrapperProps) {
	const t = useT();
	const [formState, setFormState] = useMixedState<IEditSeriesFormState>({
		groupId: null,
		title: '',
		description: '',
		timezone: '',
		location: '',
		startsAt: 0,
		endsAt: 0,
		selectedUsers: [],
		organizer: {},
		sendInvites: true,
		timezoneError: false,
		rRuleString: '',
		onDay: true,
		isPrivate: false,
		isPublic: false,
		meetBeforeMinutes: '25',
		rsvpBefore: null,
		rsvpInterval: RSVPInterval.RSVPIntervalDay,
		rsvpIntervalCount: 1,
		flags: [],
		hideParticipants: false,
	});

	const { record: eventSeries, isLoading } =
		useEndpoint<models.eventSeries.EventSeries>(
			endpoints.EventSeries.Show(eventSeriesId),
			{
				// We don't want to fetch when we have a groupId in the formState because that means
				// that we've already fetched this information once
				delayFetch: !!formState.groupId,
			},
			(eventSeries) => {
				const defaultSendInvites =
					eventSeries.type === EventSeriesType.Practice ? false : true;

				setPageTitle(t(`Editing {title}`, { title: eventSeries.title }));

				const rule = eventSeries.rRuleString
					? RRule.fromString(eventSeries?.rRuleString)
					: new RRule();

				const startsAtDateTime = new DateTime(
					new Date(
						rule.options.dtstart.getUTCFullYear(),
						rule.options.dtstart.getUTCMonth(),
						rule.options.dtstart.getUTCDate(),
						rule.options.dtstart.getUTCHours(),
						rule.options.dtstart.getUTCMinutes()
					)
				);
				const endsAtDateTime = startsAtDateTime.next(
					Granularity.second,
					eventSeries.duration
				);

				const meetBeforeMinutes = eventSeries.meetBeforeMinutes
					? eventSeries.meetBeforeMinutes.toString()
					: '0';

				const eventSeriesUsers = eventSeries.seriesUsers || [];
				const organizer: { [key: number]: boolean } = {};
				eventSeriesUsers.forEach((esu) => {
					if (esu.role === 'organizer') {
						organizer[esu.userId] = true;
					}
				});

				const s: Partial<IEditSeriesFormState> = {
					groupId: eventSeries.groupId,
					title: eventSeries.title,
					description: eventSeries.description,
					location: eventSeries.location,
					startsAt: startsAtDateTime.getUnixTimestamp(),
					endsAt: endsAtDateTime.getUnixTimestamp(),
					timezone:
						getGmtStringByTimeZoneName(eventSeries.timezone) ||
						getGmtStringByTimeZoneName(getTimeZoneFromSessionStorage()?.name),
					rRuleString: eventSeries.rRuleString,
					isPrivate: eventSeries.isPrivate,
					isPublic: eventSeries.isPublic,
					meetBeforeMinutes,
					sendInvites: defaultSendInvites,
					organizer,
					selectedUsers: eventSeriesUsers.map((seriesUser) => seriesUser.user),
					rsvpBefore: eventSeries.rsvpIntervalCount ? true : false,
					rsvpInterval:
						eventSeries.rsvpInterval ?? RSVPInterval.RSVPIntervalDay,
					rsvpIntervalCount: eventSeries.rsvpIntervalCount ?? 1,
					flags: eventSeries.flags,
					hideParticipants: eventSeries.hideParticipants,
				};

				setFormState(s);
			}
		);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Edit
			eventSeries={eventSeries}
			formState={formState}
			setFormState={setFormState}
		/>
	);
}

interface EditProps {
	formState: IEditSeriesFormState;
	setFormState: MixedStateSetter<IEditSeriesFormState>;

	eventSeries: models.eventSeries.EventSeries;
}

const Edit = ({ eventSeries, formState, setFormState }: EditProps) => {
	const t = useT();
	const [isSaving, setSaving] = useState<boolean>(false);
	const org = useCurrentOrganization();

	const handleCancel = () => popState();

	const eventSeriesUsers = eventSeries.seriesUsers || [];

	const onRemoveAttachment = (attachmentId: number) =>
		actions.eventSeries.deleteSeriesAttachment(eventSeries.id, attachmentId);

	const UseAttachments = useAttachments({
		attachments: eventSeries.eventSeriesAttachments?.map((ea) => ea.attachment),
		onRemoveAttachment,
	});

	const handleSetInvites = () =>
		setFormState({ sendInvites: !formState.sendInvites });

	const handleErrors: SubmitErrorHandler<FieldValues> = (
		errors: FieldErrors<FieldValues>
	) => {
		const errorMessages = [];

		if (errors?.title) {
			errorMessages.push(t(`Event title`));
		}

		if (errors?.location) {
			errorMessages.push(t(`Location`));
		}

		if (errors?.rsvpIntervalCount) {
			flashActions.show({
				title: t(`RSVP date can't be set before current time`),
				message: t('Please set a later RSVP date'),
			});

			return;
		}

		flashActions.show({
			title: t(`Mandatory fields missing`),
			message: errorMessages.join(', '),
		});
	};

	const handleSave = async () => {
		setSaving(true);

		if (formState.timezoneError) {
			flashActions.show({
				title: t(`Incorrect time zone`),
			});

			setSaving(false);

			return;
		}

		const rule = rrulestr(formState.rRuleString);
		const startsAtDate = new Date(formState.startsAt * 1000);
		const payloadRule: Partial<Options> = {
			dtstart: new Date(
				Date.UTC(
					startsAtDate.getFullYear(),
					startsAtDate.getMonth(),
					startsAtDate.getDate(),
					startsAtDate.getHours(),
					startsAtDate.getMinutes()
				)
			),
			tzid: getTimeZoneByGmtString(formState.timezone).name,
			until: rule.options.until,
			interval: rule.options.interval,
			freq: rule.options.freq,
		};

		switch (rule.options.freq) {
			case RRule.WEEKLY:
				payloadRule.byweekday = rule.origOptions.byweekday;
				break;
			case RRule.MONTHLY:
				if (formState.onDay) {
					payloadRule.bymonthday = rule.origOptions.bymonthday;
				} else {
					payloadRule.byweekday = rule.origOptions.byweekday;
				}
				break;
			case RRule.YEARLY:
				if (formState.onDay) {
					payloadRule.bymonth = rule.origOptions.bymonth;
					payloadRule.bymonthday = rule.origOptions.bymonthday;
				} else {
					payloadRule.bymonth = rule.origOptions.bymonth;
					payloadRule.byweekday = rule.origOptions.byweekday;
				}
		}

		await actions.eventSeries.update(
			org.id,
			eventSeries,
			formState,
			new RRule(payloadRule).toString(),
			eventSeriesUsers,
			UseAttachments.attachments
		);

		setSaving(false);
	};

	const confirmSendInvites = useDialog(
		{
			wide: true,
			symbol: 'calendar_add_on',
			title: t('Send invites?'),
			confirmLabel: t('Finish'),
			onConfirm: handleSave,
			onCancel: () => {
				const defaultSendInvites =
					eventSeries.type === EventSeriesType.Practice ? false : true;

				setFormState({ sendInvites: defaultSendInvites });
			},
			message: (
				<Column>
					<span>
						{t(
							'If you send invites, participants will be notified to take action. Otherwise invited users will automatically be added as "Going".'
						)}
					</span>
					<label className={css.sendInvites} onClick={handleSetInvites}>
						<Row columns="auto 1fr" align="center" spacing={styles.spacing._3}>
							<Input.Control
								standalone
								type="checkbox"
								checked={formState.sendInvites}
							/>
							<strong>{t('Yes, send invites')}</strong>
						</Row>
					</label>
				</Column>
			),
		},
		[formState]
	);

	const isSmallScreen = useMediaQuery({ maxWidth: toMedium });

	useComponentDidMount(() => {
		setForceNavBarBackLink(routes.Organization.Home(org.id))(store.dispatch);
	});

	return (
		<Form
			onSubmit={
				formState.selectedUsers.length > 0 ? confirmSendInvites : handleSave
			}
			onErrors={handleErrors}>
			<ActionBar.SaveBar maxWidth={PageWidths.WIDE} smallScreenFullWidth>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					onClick={handleCancel}>
					{t('Cancel')}
				</Button>
				<Button
					primary
					type="submit"
					busy={isSaving}
					disabled={isSaving}
					label={t('Save')}
					block={isSmallScreen}
					large={isSmallScreen}
				/>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner
					maxWidth={PageWidths.WIDE}
					columns={3}
					columnSpacing={styles.spacing._9}
					columnSizes="1fr 1px 0.6fr">
					<EditForm
						formState={formState}
						setFormState={setFormState}
						useAttachments={UseAttachments}
						eventSeries={eventSeries}
					/>
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>
					<EditForm
						formState={formState}
						setFormState={setFormState}
						useAttachments={UseAttachments}
						eventSeries={eventSeries}
					/>
				</LargeScreenContent.Inner>
			</SmallScreen>
		</Form>
	);
};
