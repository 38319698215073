// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.De6BGAogTB0JjjSc_8yw {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	background-color: var(--palette-white);
	color: var(--palette-gray-600);
	border-radius: var(--radius-3);
	cursor: pointer;
	padding: var(--spacing-1);
}

.dA44ErUzA078_ItUtCog {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: var(--radius-3);
	padding: var(--spacing-3) var(--spacing-5);
	-webkit-transition: all 0.15s ease-in-out;
	transition: all 0.15s ease-in-out;
}

.dA44ErUzA078_ItUtCog.LY7I9Zasl8j2Y1TMnCfl {
		background-color: var(--palette-blue-200);
		color: var(--palette-blue-500);
		font-weight: var(--font-weight-semibold);
	}

.t16wC1m1eUn3q5JqFyPa {
	font-size: var(--font-size-sm);
}
`, "",{"version":3,"sources":["webpack://./components/view-toggle/styles.css"],"names":[],"mappings":"AAAA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,sCAAsC;CACtC,8BAA8B;CAC9B,8BAA8B;CAC9B,eAAe;CACf,yBAAyB;AAC1B;;AAEA;CACC,oBAAa;CAAb,oBAAa;CAAb,aAAa;CACb,8BAA8B;CAC9B,0CAA0C;CAC1C,yCAAiC;CAAjC,iCAAiC;AAOlC;;AALC;EACC,yCAAyC;EACzC,8BAA8B;EAC9B,wCAAwC;CACzC;;AAGD;CACC,8BAA8B;AAC/B","sourcesContent":[".wrapper {\n\tdisplay: flex;\n\tbackground-color: var(--palette-white);\n\tcolor: var(--palette-gray-600);\n\tborder-radius: var(--radius-3);\n\tcursor: pointer;\n\tpadding: var(--spacing-1);\n}\n\n.symbolWrapper {\n\tdisplay: flex;\n\tborder-radius: var(--radius-3);\n\tpadding: var(--spacing-3) var(--spacing-5);\n\ttransition: all 0.15s ease-in-out;\n\n\t&.active {\n\t\tbackground-color: var(--palette-blue-200);\n\t\tcolor: var(--palette-blue-500);\n\t\tfont-weight: var(--font-weight-semibold);\n\t}\n}\n\n.text {\n\tfont-size: var(--font-size-sm);\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `De6BGAogTB0JjjSc_8yw`;
export var symbolWrapper = `dA44ErUzA078_ItUtCog`;
export var active = `LY7I9Zasl8j2Y1TMnCfl`;
export var text = `t16wC1m1eUn3q5JqFyPa`;
export default ___CSS_LOADER_EXPORT___;
