// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qb0wDNUTF8GzDamRs7pH {
	position: relative;
	overflow: auto;
	-webkit-overflow-scrolling: touch;
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
}
`, "",{"version":3,"sources":["webpack://./components/group/post/users-modal/style.css"],"names":[],"mappings":"AAAA;CACC,kBAAkB;CAClB,cAAc;CACd,iCAAiC;CACjC,mBAAO;KAAP,WAAO;SAAP,OAAO;AACR","sourcesContent":[".wrapper {\n\tposition: relative;\n\toverflow: auto;\n\t-webkit-overflow-scrolling: touch;\n\tflex: 1;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `qb0wDNUTF8GzDamRs7pH`;
export default ___CSS_LOADER_EXPORT___;
