import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import Link from 'pkg/router/Link';
import { useCurrentOrganization } from 'pkg/identity';

import SideBarTop from 'routes/organization/contacts/single/sidebar/top';
import ContactDetails from 'routes/organization/contacts/single/sidebar/Details';
import BillingMissingEntity from 'routes/organization/contacts/single/BillingMissingEntity';
import BillingAddress from 'routes/organization/contacts/single/BillingAddress';

import Column from 'components/layout/column';
import * as SideBarLayout from 'components/layout/SideBarPage';

import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

interface SideBarProps {
	groupId: number;
	contact: models.user.User;
	handleOpenModal: (name: string) => void;
	handleRestoreUser: () => void;
	refreshMemberships: () => void;
	refreshUser: () => void;
}

const SideBar: React.FC<React.PropsWithChildren<SideBarProps>> = ({
	groupId,
	contact,
	handleOpenModal,
	handleRestoreUser,
	refreshMemberships,
	refreshUser,
}) => {
	const org = useCurrentOrganization();

	return (
		<SideBarLayout.SideBar>
			<SideBarLayout.TopWrapper>
				<SideBarTop
					groupId={groupId}
					contact={contact}
					restoreUser={handleRestoreUser}
					openModal={handleOpenModal}
					refreshMemberships={refreshMemberships}
					refreshUser={refreshUser}
				/>
			</SideBarLayout.TopWrapper>
			<ContextMenu.Divider />
			<Column spacing={styles.spacing._6} className={css.sidebarSection}>
				<div>
					<div className={css.sidebarHeading}>
						<p>{t('Contact details')}</p>
						<Link href={routes.Contact.Fields(org.id, contact.id)}>
							{t('View all')}
						</Link>
					</div>
					<ContactDetails contact={contact} />
				</div>
				<BillingAddress user={contact} />
				<div>
					<p className={css.sidebarHeading}>{t('Billing contact')}</p>
					{contact?.billingUser?.id ? (
						<ContactDetails contact={contact.billingUser} />
					) : (
						<BillingMissingEntity
							contact={contact}
							handleOpenModal={handleOpenModal}
						/>
					)}
				</div>
			</Column>
		</SideBarLayout.SideBar>
	);
};

export default SideBar;
