import * as models from 'pkg/api/models';

interface OrganizationUserProfileRegistrationsProps {
	user: models.user.User;
}

export default function OrganizationUserProfileRegistrations({
	user,
}: OrganizationUserProfileRegistrationsProps): JSX.Element {
	return <p>Showing Registrations for {models.user.fullName(user)}</p>;
}
