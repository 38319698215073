import { APP_SET_TABBAR_VISIBILITY } from 'pkg/actions/action-types';
import { setDrawerVisibility } from 'pkg/actions/app';

import { replaceState } from 'pkg/router/state';
import { RootState } from 'pkg/reducers';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import store from 'pkg/store/createStore';
import { getCurrentIdentityContextValues } from 'pkg/identity';
import { RouteProps } from 'pkg/router/middlewares';
import { tlog } from 'pkg/tlog';
import { getOrganizationIdentityContextValues } from 'pkg/identity/organization';

import { FullScreenPageWithoutHeader } from 'components/layout/PageTemplates';
import { MissingInformation } from 'components/user/missing-information';

export const resetErrors = (props: RouteProps): RouteProps => {
	const state: RootState = store.getState();

	if (state.app.apiError !== null) {
		store.dispatch(actions.app.resetApiError());
	}

	return props;
};

export const checkMembershipForPaymentCap = (props: RouteProps): RouteProps => {
	const identity = getCurrentIdentityContextValues();

	if (
		!models.membership.hasCapability(
			identity.membership,
			models.membership.Capabilities.PaymentAdmin
		)
	) {
		return { ...props, redirectTo: routes.Home() };
	}

	return props;
};

export function organizationHasPaymentProvider(props: RouteProps): RouteProps {
	const orgIdentity = getOrganizationIdentityContextValues();
	if (!models.group.hasActivePaymentProvider(orgIdentity.organization)) {
		return { ...props, redirectTo: routes.Home() };
	}

	return props;
}

export const groupHasFeature = (props: RouteProps): RouteProps => {
	const identity = getCurrentIdentityContextValues();

	if (
		props.feature &&
		!models.group.hasFeature(identity.membership.group, props.feature)
	) {
		return { ...props, redirectTo: routes.Home() };
	}

	return props;
};

export const hasUser = (props: RouteProps): RouteProps => {
	const identity = getCurrentIdentityContextValues();

	if (!identity.membership) {
		replaceState(routes.Home());
	}

	return props;
};

export const checkRequiredUserInformation = (props: RouteProps): RouteProps => {
	const identity = getCurrentIdentityContextValues();
	const orgIdentity = getOrganizationIdentityContextValues();

	const currentMembership = identity.membership;
	const user = orgIdentity.user;

	const targetUserId = identity.membership.targetUserId;
	const targetUser = orgIdentity.wards?.find((u) => u.id === targetUserId);

	const setNavigationVisibility = (value: boolean) => {
		store.dispatch({
			type: APP_SET_TABBAR_VISIBILITY,
			tabBarVisible: value,
		});

		setDrawerVisibility(value);
	};

	// Do nothing if this Route has the userInformationNotRequired prop.
	// This can be used for "user settings", "account settings", "login", and other routes that should
	// always be accessible even if the user is missing required information.
	if (props.userInformationNotRequired) {
		setNavigationVisibility(true);
		return props;
	}

	if (currentMembership?.isOrganizationMembership) {
		setNavigationVisibility(true);
		return props;
	}

	// When a user saves the "missing user information form", it adds the query param "informationSaved".
	// If that exists we want to clear it and return props to "unlock" the app again.
	const urlParams = new URLSearchParams(document.location.search);
	const informationSaved = urlParams.get('information_saved');

	if (informationSaved === 'true') {
		setNavigationVisibility(true);

		replaceState(document.location.pathname);

		return props;
	}

	let missingInformation: boolean = false;

	if (models.membership.isParent(currentMembership) && targetUser) {
		// Check if user is parent and if their child is missing information
		const targetUserMembership = targetUser.groups?.find(
			(m) => m.groupId === currentMembership.groupId
		);

		if (
			models.membership.isPlayer(targetUserMembership) &&
			models.user.isMissingRequiredInformation(targetUser)
		) {
			missingInformation = true;
		}
	} else if (
		// Check if currentUser is a player, we don't show the missing information screen to staff/admins
		models.membership.isPlayer(currentMembership) &&
		models.user.isMissingRequiredInformation(user)
	) {
		missingInformation = true;
	} else if (
		orgIdentity.organization?.country?.code === 'SE' &&
		models.membership.isAdminOrStaff(currentMembership) &&
		models.user.isMissingRequiredInformation(user)
	) {
		missingInformation = true;
	}

	if (missingInformation) {
		setNavigationVisibility(false);

		return {
			...props,
			wrapWith: FullScreenPageWithoutHeader,
			component: MissingInformation,
		};
	}

	setNavigationVisibility(true);

	return props;
};

export const logMalformedLinks = (props: RouteProps): RouteProps => {
	if (props.path.includes(':') || props.path.includes('undefined')) {
		tlog.message(`malformed link: ${props.path}`);
	}

	return props;
};
