import { useT } from '@transifex/react';
import { Fragment, ReactNode } from 'react';

import { palette } from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';
import { link } from 'pkg/router/utils';
import { formatToCurrency } from 'pkg/i18n/formatters';
import * as arrays from 'pkg/arrays';
import DateTime from 'pkg/datetime';
import useTooltip from 'pkg/hooks/useTooltip';
import * as actions from 'pkg/actions';
import useToggleState from 'pkg/hooks/useToggleState';

import {
	useMarkAsPaid,
	useMarkAsRefunded,
	useMarkAsUncollectible,
	useMarkAsVoid,
	useRemoveInvoice,
} from 'routes/organization/user-profile/overview/actions';
import RefundModal from 'routes/payments/orders/single/refund_modal';

import MaterialSymbol from 'components/material-symbols';

import StatusLabel from 'components/payment_platform/status/label';
import { Spinner } from 'components/loaders/spinner';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

import * as css from './styles.css';

interface InvoicesProps {
	user: models.user.User;
}

export default function Invoices({ user }: InvoicesProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const columns: Table.HeaderColumn[] = [
		{ content: t('Invoice number') },
		{ content: t('Amount due'), align: 'right', width: 'max-content' },
		{ content: t('Status'), width: 'max-content', align: 'center' },
		{ content: t('Billed to') },
		{ content: t('Products for') },
		{ content: t('Due date'), align: 'right' },
		{ content: '', width: 'max-content' },
	];

	const {
		records: invoices,
		isLoading,
		replaceRecord,
		removeRecord,
	} = useCollection<models.order.Order>(endpoints.Orders.Index(), {
		showAllResults: true,
		queryParams: new URLSearchParams({
			associated_user_id: user.id.toString(),
			group_id: org.id.toString(),
			count: '5',
		}),
	});

	const orders: models.order.Order[] = invoices?.sort(
		(a, b) => b.createdAt - a.createdAt
	);

	const emptyState = {
		title: t('No invoices found'),
		content: t('There are no invoices associated with {name}.', {
			name: models.user.fullName(user),
		}),
		image: (
			<MaterialSymbol
				actualSize
				scale={3}
				variant="request_quote"
				className={css.emptyStateIcon}
			/>
		),
		className: css.emptyState,
	};

	return (
		<Table.Table
			columns={columns}
			isLoading={isLoading}
			emptyState={emptyState}>
			{orders.map((order) => (
				<Order
					key={order.id}
					user={user}
					order={order}
					replaceRecord={replaceRecord}
					removeRecord={removeRecord}
				/>
			))}
		</Table.Table>
	);
}

interface OrderProps {
	user: models.user.User;
	order: models.order.Order;

	replaceRecord: (
		record: models.order.Order,
		key?: keyof models.order.Order
	) => void;
	removeRecord: (recordId: number) => void;
}

function Order({
	user,
	order,
	replaceRecord,
	removeRecord,
}: OrderProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const [showingRefundModal, showRefundModal, hideRefundModal] =
		useToggleState(false);

	const invoiceNumber: string = order?.invoiceNumber.toString() || '—';

	const amountDue: string = formatToCurrency(
		order.amountDue / 100,
		org.currency
	);

	let dueDate: string = '—';

	if (order.dueDate) {
		dueDate = DateTime.fromTimestamp(order.dueDate).toLocaleDateString();
	}

	const users: models.user.User[] = order.userProducts?.map((up) => up.user);

	const usersNamesList = arrays.penultimateJoin(
		users.map((user) => models.user.fullName(user)),
		', ',
		t('and')
	);

	const orderUrl = link(routes.Order.Show(org.id, order.id), {
		return_url: routes.Profile.Show(org.id, user.id),
	});

	let customerUrl: Nullable<string> = null;

	if (!!order.customerUserId && order.customerUserId !== user.id) {
		customerUrl = routes.Profile.Show(org.id, order.customerUserId);
	}

	const { tooltip, onMouseEnter } = useTooltip(usersNamesList);

	let productsForUsers: ReactNode = <span>—</span>;

	if (users?.length === 1) {
		productsForUsers = <span>{models.user.fullName(users[0])}</span>;
	} else if (users?.length > 1) {
		productsForUsers = (
			<Fragment>
				{tooltip}
				<span onMouseEnter={onMouseEnter}>
					{t('{name} and {num} more', {
						name: models.user.fullName(users[0]),
						num: users.length - 1,
						_comment: 'multiple billed to users',
					})}
				</span>
			</Fragment>
		);
	}

	const invoiceActions: ReactNode[] = [
		<ContextMenu.LinkItem
			key="order-url"
			href={orderUrl}
			className={css.contextItemWithExtraIcon}>
			<ContextMenu.ItemIcon name="request_quote" />
			<span>{t('View invoice')}</span>
			<ContextMenu.ItemIcon name="arrow_forward" />
		</ContextMenu.LinkItem>,
	];

	const paymentActions: ReactNode[] = [];

	const orderIsProcessing = order.jobStatus === 'processing';

	const processingTooltip = orderIsProcessing
		? t(
				'Currently processing in your payment provider. No further actions can be taken until it is completed.',
				{ _context: 'payments' }
			)
		: '';

	const processingIndicator = orderIsProcessing && (
		<Spinner color={palette.black} size="16px" />
	);

	const handleMarkAsPaid = useMarkAsPaid(order, async () => {
		replaceRecord({ ...order, status: models.order.Statuses.Paid });
	});

	const handleMarkAsUncollectible = useMarkAsUncollectible(order, async () => {
		replaceRecord({ ...order, status: models.order.Statuses.Uncollectible });
	});

	const handleMarkAsRefunded = useMarkAsRefunded(order, async () => {
		replaceRecord({ ...order, status: models.order.Statuses.Refunded });
	});

	const handleMarkAsVoid = useMarkAsVoid(order, async () => {
		removeRecord(order.id);
	});

	const handleRemove = useRemoveInvoice(order, async () => {
		removeRecord(order.id);
	});

	const handleSendReminder = () => actions.order.sendReminder([order.id]);

	const handleSendReceipt = () => actions.order.sendReceipt([order.id]);

	if (models.hasAllowedAction(user, models.Action.OrderMarkAsPaid)) {
		paymentActions.push(
			<ContextMenu.Item
				key="mark_as_paid"
				tooltip={processingTooltip}
				onClick={handleMarkAsPaid}>
				<ContextMenu.ItemIcon name="verified" />
				<span>{t('Mark as paid')}</span>
			</ContextMenu.Item>
		);
	}

	if (models.hasAllowedAction(user, models.Action.OrderMarkAsUncollectible)) {
		paymentActions.push(
			<ContextMenu.Item
				key="mark_as_uncollectible"
				disabled={orderIsProcessing}
				tooltip={processingTooltip}
				onClick={handleMarkAsUncollectible}>
				<ContextMenu.ItemIcon name="unpublished" />
				<span>{t('Mark as uncollectible')}</span>
				{processingIndicator}
			</ContextMenu.Item>
		);
	}

	if (models.hasAllowedAction(user, models.Action.OrderRefund)) {
		if (order.flags.includes('auto_refundable')) {
			paymentActions.push(
				<ContextMenu.Item
					key="order_refund"
					disabled={orderIsProcessing}
					tooltip={processingTooltip}
					onClick={showRefundModal}
					className={css.contextItemWithExtraIcon}>
					<ContextMenu.ItemIcon name="currency_exchange" />
					<span>{t('Refund invoice')}</span>
					{processingIndicator}
				</ContextMenu.Item>
			);
		} else {
			paymentActions.push(
				<ContextMenu.Item
					key="order_refund"
					disabled={orderIsProcessing}
					tooltip={processingTooltip}
					onClick={handleMarkAsRefunded}
					className={css.contextItemWithExtraIcon}>
					<ContextMenu.ItemIcon name="currency_exchange" />
					<span>{t('Mark as refunded')}</span>
					{processingIndicator}
				</ContextMenu.Item>
			);
		}
	}

	if (models.hasAllowedAction(user, models.Action.OrderSendReminder)) {
		paymentActions.push(
			<ContextMenu.Item
				key="send_reminder"
				disabled={orderIsProcessing}
				tooltip={processingTooltip}
				onClick={handleSendReminder}
				className={css.contextItemWithExtraIcon}>
				<ContextMenu.ItemIcon name="notification_add" />
				<span>{t('Send reminder')}</span>
				{processingIndicator}
			</ContextMenu.Item>
		);
	}

	if (models.hasAllowedAction(user, models.Action.OrderSendReceipt)) {
		paymentActions.push(
			<ContextMenu.Item
				key="send_receipt"
				disabled={orderIsProcessing}
				tooltip={processingTooltip}
				onClick={handleSendReceipt}
				className={css.contextItemWithExtraIcon}>
				<ContextMenu.ItemIcon name="receipt_long" />
				<span>{t('Send receipt')}</span>
				{processingIndicator}
			</ContextMenu.Item>
		);
	}

	if (models.hasAllowedAction(user, models.Action.OrderDuplicate)) {
		paymentActions.push(
			<ContextMenu.LinkItem
				key="order_duplicate"
				href={link(routes.Invoice.New(org.id), {
					returnUrl: routes.Organization.User.Profile.Show(org.id, user.id),
					groupId: order.groupId,
					orderId: order.id,
				})}
				tooltip={processingTooltip}
				className={css.contextItemWithExtraIcon}>
				<ContextMenu.ItemIcon name="control_point_duplicate" />
				<span>{t('Duplicate')}</span>
			</ContextMenu.LinkItem>
		);
	}

	if (models.hasAllowedAction(user, models.Action.OrderMarkAsVoid)) {
		paymentActions.push(
			<ContextMenu.Divider />,
			<ContextMenu.Item
				caution
				key="mark_as_void"
				disabled={orderIsProcessing}
				tooltip={processingTooltip}
				onClick={handleMarkAsVoid}
				className={css.contextItemWithExtraIcon}>
				<ContextMenu.ItemIcon name="contract_delete" />
				<span>{t('Mark as void', { _context: 'payments invoice' })}</span>
				{processingIndicator}
			</ContextMenu.Item>
		);
	}

	if (models.hasAllowedAction(user, models.Action.OrderRemove)) {
		paymentActions.push(
			paymentActions.length > 0 && <ContextMenu.Divider />,
			<ContextMenu.Item
				caution
				key="delete"
				disabled={orderIsProcessing}
				tooltip={processingTooltip}
				onClick={handleRemove}
				className={css.contextItemWithExtraIcon}>
				<ContextMenu.ItemIcon name="delete" />
				<span>{t('Remove invoice', { _context: 'payments invoice' })}</span>
				{processingIndicator}
			</ContextMenu.Item>
		);
	}

	if (paymentActions.length > 0) {
		invoiceActions.push(<ContextMenu.Divider />, ...paymentActions);
	}

	return (
		<Fragment>
			<Table.Row>
				<Table.LinkCell href={orderUrl}>{invoiceNumber}</Table.LinkCell>
				<Table.LinkCell href={orderUrl} align="right">
					{amountDue}
				</Table.LinkCell>
				<Table.LinkCell href={orderUrl}>
					<StatusLabel status={models.order.getStatus(order)} />
				</Table.LinkCell>
				<Table.LinkCell href={customerUrl}>
					{models.user.fullName(order.customerUser)}
				</Table.LinkCell>
				<Table.LinkCell href={orderUrl}>{productsForUsers}</Table.LinkCell>
				<Table.Cell align="right">{dueDate}</Table.Cell>
				<Table.ActionCell>
					<ContextMenu.Menu
						toggleWith={
							<ContextMenu.TableTrigger>
								<MaterialSymbol actualSize variant="more_horiz" scale={1.3} />
							</ContextMenu.TableTrigger>
						}>
						{invoiceActions}
					</ContextMenu.Menu>
				</Table.ActionCell>
			</Table.Row>
			{showingRefundModal && (
				<RefundModal
					order={order}
					hideModal={hideRefundModal}
					currency={org.currency}
				/>
			)}
		</Fragment>
	);
}
