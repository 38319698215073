import React, { useEffect } from 'react';
import { t } from '@transifex/native';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCurrentMembership, useCurrentOrganization } from 'pkg/identity';
import * as routes from 'pkg/router/routes';

import FormOverview from 'routes/forms/single/overview';
import Submissions from 'routes/forms/single/Submissions';
import RegistrationSubscriptions from 'routes/forms/single/subscriptions';
import RegistrationOrders from 'routes/forms/single/invoices';

import { ViewSettings } from 'components/layout/PageTemplates';
import { Spinner } from 'components/loaders/spinner';

export enum SingleFormTabViews {
	Overview = 'overview',
	Submissions = 'submissions',
	Subscriptions = 'subscriptions',
	Invoices = 'invoices',
}
interface SingleFormProps extends ViewSettings {
	groupId: number;
	formId: number;
}

const SingleForm: React.FC<React.PropsWithChildren<SingleFormProps>> = ({
	groupId,
	formId,
	setPageTitle,
	currentTab,
	setPageSubNavItems,
}) => {
	const org = useCurrentOrganization();
	const membership = useCurrentMembership();
	const { record: form, isLoading: isFormLoading } =
		useEndpoint<models.form.Form>(endpoints.Forms.Show(formId));

	const subNavItems = [
		{
			title: t('Overview'),
			testid: 'subnav.dashboard',
			href: routes.Registrations.Single(org.id, groupId, formId, 'overview'),
		},
		{
			title: t('Submissions'),
			testid: 'subnav.submissions',
			href: routes.Registrations.Single(org.id, groupId, formId, 'submissions'),
		},
	];

	if (
		models.membership.hasCapability(
			membership,
			models.membership.Capabilities.PaymentAdmin
		) &&
		models.group.hasActivePaymentProvider(org)
	) {
		subNavItems.push(
			{
				title: t('Invoices'),
				testid: 'subnav.invoices',
				href: routes.Registrations.Single(
					org.id,
					groupId,
					formId,
					SingleFormTabViews.Invoices
				),
			},
			{
				title: t('Subscriptions'),
				testid: 'subnav.subscriptions',
				href: routes.Registrations.Single(
					org.id,
					groupId,
					formId,
					'subscriptions'
				),
			}
		);
	}

	useEffect(() => {
		if (form.id) {
			setPageTitle(form.title);
			setPageSubNavItems(subNavItems);
		}
	}, [form.id]);

	if (isFormLoading) {
		return <Spinner />;
	}
	switch (currentTab) {
		case SingleFormTabViews.Overview:
			return <FormOverview groupId={groupId} formId={formId} />;
		case SingleFormTabViews.Submissions:
			return <Submissions groupId={groupId} formId={formId} />;
		case SingleFormTabViews.Subscriptions:
			return (
				<RegistrationSubscriptions organizationId={groupId} formId={formId} />
			);
		case SingleFormTabViews.Invoices:
			return <RegistrationOrders formId={formId} />;
	}
	return null;
};

export default SingleForm;
