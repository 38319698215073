import { t } from '@transifex/native';
import { useEffect } from 'react';

import * as flashActions from 'pkg/actions/flashes';

import * as routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as sdk from 'pkg/core/sdk';
import useComponentDidMount from 'pkg/hooks/useComponentDidMount';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useEndpoint } from 'pkg/api/use_endpoint';

import { ShowFormResponse } from 'routes/forms/registration/preview';

import StatusScreen from 'components/status-screen';

import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

interface CancelProps {
	setPageTitle: (title: string) => void;
}

export default function Cancel({ setPageTitle }: CancelProps) {
	const route = useCurrentRoute();
	const formSubmissionId = route?.formSubmissionId;

	const guid = route.guid;

	const { record: response, isLoading } = useEndpoint<ShowFormResponse>(
		endpoints.Forms.PublicShow(guid)
	);

	useEffect(() => {
		if (!isLoading && response) {
			setPageTitle(response.form.title);
		}
	}, [isLoading]);

	const cancelFormSubmission = async () => {
		if (formSubmissionId) {
			const response = await sdk.post(
				endpoints.FormSubmissions.CancelFormSubmission(
					parseInt(formSubmissionId)
				)
			);
			if (!response.ok) {
				flashActions.show(
					{
						title: t('Your form submission has been cancelled.'),
					},
					response.status
				);
			}
		}
	};

	useComponentDidMount(() => {
		cancelFormSubmission();
	});

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<StatusScreen
			fullscreen
			status="cancel"
			title={t('Form submission cancelled!')}
			description={t(
				'The form submission was cancelled and your response was not recorded.'
			)}>
			<Button primary large href={routes.Registration.Preview(guid)}>
				{t('Back to registration')}
			</Button>
		</StatusScreen>
	);
}
