import * as models from 'pkg/api/models';

interface OrganizationUserProfileCalendarProps {
	user: models.user.User;
}

export default function OrganizationUserProfileCalendar({
	user,
}: OrganizationUserProfileCalendarProps): JSX.Element {
	return <p>Showing Calendar for {models.user.fullName(user)}</p>;
}
