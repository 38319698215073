import { t } from '@transifex/native';

import { IFetchAllQueryParams } from 'pkg/actions/services/subscription';

import { Linkable } from 'pkg/api/models/linkable';
import { Dateable } from 'pkg/api/models/dateable';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { TaxRate } from 'pkg/api/models/tax_rate';
import { UserProduct } from 'pkg/api/models/user_product';

import { IStatusInfo } from 'components/payment_platform/status/label';

type SubscriptionRowTypes = 'product' | 'fee' | 'discount';

export enum SubscriptionTypes {
	Installment = 'installment',
	Subscription = 'subscription',
}

export enum SubscriptionStatus {
	Unknown = 'unknown',
	Active = 'active',
	Canceled = 'canceled',
	Trialing = 'trialing',
	Scheduled = 'scheduled',
	Completed = 'completed',
	Pending = 'pending',
	Uncollectible = 'uncollectible',
}

export interface ExportSubscriptionsPayload {
	recipientName?: boolean;
	recipientUserId?: boolean;
	email?: boolean;
	assignedContacts?: boolean;
	status?: boolean;
	createdAt?: boolean;
	dueDate?: boolean;
	billingInterval?: boolean;
	collectionMethod?: boolean;
	addressData?: boolean;
	products?: boolean;
	subscriptionId?: boolean;
}

export interface SubscriptionRow extends Dateable {
	id: number;
	productPriceId: number;
	quantity: number;
	subscriptionId: number;
	taxRateId: number;
	userId?: number;
	userProductId: number;
	validTo: number;
	amount?: number;
	discountId?: number;
	type: SubscriptionRowTypes;

	taxRate: TaxRate;
	userProduct: UserProduct;
	productPrice: models.productPrice.ProductPrice;
	discount?: models.discount.Discount;
	user?: models.user.User;
}

export function isProductRow(row: SubscriptionRow) {
	if (row.type === 'product') {
		return true;
	}

	return false;
}

export function rowIsFee(row: SubscriptionRow) {
	if (row.type === 'fee') {
		return true;
	}

	return false;
}

export function getSubscriptionRowsTotalCost(
	subscriptionRows: SubscriptionRow[] = []
) {
	let excludedTax = 0;
	let subTotal = 0;

	if (!!subscriptionRows?.length) {
		subscriptionRows.forEach((subscriptionRow) => {
			if (subscriptionRow.type === 'fee') {
				subTotal += subscriptionRow.amount / 100;
			} else if (subscriptionRow.type === 'discount') {
				// need to convert to positive value, we recive negative from the api
				const conv = subscriptionRow.amount * -1;

				subTotal -= conv / 100;
			} else {
				const price = subscriptionRow.productPrice;
				const itemCost = (price.cost / 100) * subscriptionRow.quantity;
				subTotal += (price.cost / 100) * subscriptionRow.quantity;

				if (subscriptionRow.taxRateId) {
					const newAmountWithTax = models.taxRate.getAmountWithTaxRate(
						itemCost,
						subscriptionRow.taxRate
					);

					if (!subscriptionRow.taxRate.inclusive) {
						excludedTax += newAmountWithTax;
					}
				}
			}
		});
	}

	return excludedTax + subTotal;
}

export interface Subscription extends Dateable, Linkable {
	accountId: number;
	currency: string;
	currentPeriodStart: number;
	currentPeriodEnd: number;
	customerUserId: number;
	collectionMethod: 'automatic' | 'manual';
	description: string;
	daysUntilDue: number;
	groupId: number;
	id: number;
	latestInvoiceDueDate: number;
	recurringInterval: string;
	recurringIntervalCount: number;
	startAt: number;
	endAt: number;
	trialEndAt: number;
	jobStatus: string;
	stripeCouponId: number;
	paymentProviderId: string;
	paymentMethodId: number;
	status: SubscriptionStatus;
	installmentCount: number;
	publicId: string;
	publicUrl: string;
	installmentsPaid: number;
	installmentPaidAmount: number;
	installmentTotalAmount: number;

	customerUser: models.user.User;
	rows: SubscriptionRow[];
	type: SubscriptionTypes;
	orders: models.order.Order[];
}

export function getPaidOrders(subscription: Subscription) {
	return (
		subscription.orders?.filter(
			(o) => o.status === models.order.Statuses.Paid
		) || []
	);
}

export function getAmountPaid(subscription: Subscription) {
	const paidOrders = getPaidOrders(subscription);
	let amountPaid = 0;

	paidOrders?.forEach((o) => (amountPaid += o.amountDue));

	return amountPaid;
}

// Return the discount if there's a row that is a discount
export function findDiscount(subscription: Subscription) {
	return subscription.rows?.filter((r) => r.type === 'discount')[0]?.discount;
}

export function findProductRows(subscription: Subscription) {
	return subscription.rows?.filter((r) => r.type === 'product');
}

export function getStatus(subscription: Subscription): IStatusInfo {
	if (subscription.status === 'trialing') {
		return { name: subscription.status, trialEndAt: subscription.trialEndAt };
	}

	if (
		subscription.status === 'active' &&
		!!subscription.endAt &&
		subscription.type !== SubscriptionTypes.Installment
	) {
		return { name: 'cancels_at', endAt: subscription.endAt };
	}

	return { name: subscription.status === 'unknown' ? '' : subscription.status };
}

export function getCollectionMethodString(
	subscription: Subscription,
	orderId = 0
): string {
	let isFirstInvoice = false;

	if (
		subscription &&
		subscription.orders?.length &&
		subscription.orders[0]?.id === orderId
	) {
		isFirstInvoice = true;
	}

	if (subscription.collectionMethod === 'automatic' && !isFirstInvoice) {
		return t('Auto charge');
	}

	return t('Email invoice');
}

export function getExportFields() {
	return [
		{
			key: 'recipientName',
			label: t('Recipient name'),
		},
		{
			key: 'recipientUserID',
			label: t('Recipient user ID'),
		},
		{ key: 'email', label: t('Email') },
		{
			key: 'assignedContacts',
			label: t('Assigned contacts'),
		},
		{ key: 'status', label: t('Status') },
		{ key: 'createdAt', label: t('Created') },
		{ key: 'dueDate', label: t('Due date') },
		{
			key: 'billingInterval',
			label: t('Billing interval'),
		},
		{
			key: 'collectionMethod',
			label: t('Collection method'),
		},
		{
			key: 'addressData',
			label: t('Address data'),
		},
		{ key: 'products', label: t('Products') },
		{
			key: 'subscriptionId',
			label: t('Subscription ID'),
		},
	];
}

export function getTypeString(subscription: Subscription) {
	if (subscription.type === SubscriptionTypes.Subscription) {
		return t('Subscription');
	}

	return t('Installment');
}

export async function exportSubscriptions(
	filters: IFetchAllQueryParams,
	payload: ExportSubscriptionsPayload
): Promise<[boolean, string]> {
	return models.createExport(
		endpoints.Subscriptions.ExportSubscriptions(),
		payload,
		filters
	);
}

export function subscriptionCanBeEdited(subscription: Subscription): boolean {
	if (
		subscription.status === SubscriptionStatus.Canceled ||
		subscription.status === SubscriptionStatus.Completed
	) {
		return false;
	}

	return true;
}

export default Subscription;
