import {
	Children,
	useState,
	ReactElement,
	Fragment,
	ReactNode,
	useRef,
	Dispatch,
	SetStateAction,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import styled, { css as scss } from 'styled-components';
import { t } from '@transifex/native';

import * as styles from 'pkg/config/styles';
import * as breakpoints from 'pkg/config/breakpoints';
import { ActionBarHeight, PageWidths } from 'pkg/config/sizes';

import { Filter, Filters, FilterSetter } from 'pkg/filters/use_filters';
import rgba from 'pkg/rgba';
import { useQueryState } from 'pkg/hooks/query-state';
import { cssClasses } from 'pkg/css/utils';
import Link from 'pkg/router/Link';
import { createFilter } from 'pkg/filters';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Icon from 'components/icon';
import MaterialSymbol from 'components/material-symbols';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import * as iconStyles from 'components/icon/styles.css';
import SearchInput, { SearchInputProps } from 'components/form/SearchInput';
import { usePageActions } from 'components/navigation/header/small_screen/page_actions/Context';
import Row from 'components/layout/row';
import { useAppState } from 'components/application/state';

import * as buttonStyles from 'design/button/styles.css';
import Button, { ButtonVariant, FilterButton } from 'design/button';
import * as Context from 'design/context_menu';

import * as css from './actionbar/styles.css';

export const ActionBarWrapper = styled.div<{
	open: boolean;
	noBorder?: boolean;
	inModal?: boolean;
}>`
	height: ${({ open }) => (open ? 'auto' : `${ActionBarHeight.STANDARD}px`)};
	background-color: var(--palette-white);
	width: 100%;
	z-index: ${styles.zIndex.navigation - 1};
	border-bottom: ${({ noBorder }) =>
		noBorder ? 'none' : `1px solid var(--palette-gray-300)`};
	position: sticky;
	top: 0;
	display: flex;
	justify-content: center;

	@media print {
		display: none;
	}

	@media all and (max-width: ${breakpoints.toMedium}px) {
		position: relative;
		top: auto;
		height: ${({ open }) => (open ? 'auto' : `${ActionBarHeight.MEDIUM}px`)};
		width: 100%;
		max-width: 100vw;
	}

	/* For some reason overriding height doesn't work without important */
	${({ inModal }) =>
		inModal &&
		scss`
			height: auto !important;
		`}
`;

const ContentWrapper = styled.div<{ maxWidth: PageWidths; inModal?: boolean }>`
	display: block;
	align-items: center;
	width: 100%;
	max-width: ${({ maxWidth }) => maxWidth && `${maxWidth}px`};
	padding: var(--spacing-5) var(--spacing-6);

	@media ${breakpoints.small} {
		padding: var(--spacing-5);
		display: block;
	}

	${({ inModal }) =>
		inModal &&
		scss`
			padding: var(--spacing-5);

			@media ${breakpoints.small} {
				padding: var(--spacing-4);
			}
		`}
`;

const ActionsWrapper = styled.div<{
	smallScreenFullWidth?: boolean;
}>`
	display: flex;
	align-items: center;
	gap: var(--spacing-3);

	&:empty {
		display: none;
	}

	.${iconStyles.icon} {
		font-size: var(--font-size-lg);
	}

	${({ smallScreenFullWidth }) =>
		smallScreenFullWidth &&
		scss`
			margin-left: auto;
			width: 100%;
		`};
`;

const Top = styled.div<{ hasLeftContent: boolean }>`
	display: flex;
	align-items: center;
	justify-content: ${({ hasLeftContent }) =>
		hasLeftContent ? 'space-between' : 'flex-end'};
	width: 100%;
	gap: var(--spacing-3);
`;

const Bottom = styled.div`
	display: grid;
	grid-gap: var(--spacing-3);
	margin: var(--spacing-5) 0px var(--spacing-2) 0px;

	.${buttonStyles.button} {
		@media ${breakpoints.small} {
			width: 100%;
		}
	}
`;

export const Label = styled.div`
	color: var(--palette-gray-500);
`;

const ActionBarSearch = styled.div<{ fullWidth?: boolean }>`
	width: 100%;

	@media not ${breakpoints.small} {
		max-width: 440px;
	}

	/* For some reason overriding max-width doesn't work without important */
	${({ fullWidth }) =>
		fullWidth &&
		scss`
			max-width: 100% !important;
		`}
`;

const SaveBarWrapper = styled.div`
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	height: calc(${ActionBarHeight.MEDIUM}px + env(safe-area-inset-bottom));
	background-color: var(--palette-white);
	z-index: ${styles.zIndex.navigation - 1};
	box-shadow: ${`0px -1px 10px ${rgba(
		styles.palette.gray[800],
		0.1
	)}, 0px -2px 1px ${rgba(styles.palette.gray[800], 0.06)}`};
	display: flex;
	justify-content: center;
	padding: 0 var(--spacing-4) env(safe-area-inset-bottom) var(--spacing-4);
`;

interface BarProps {
	maxWidth?: PageWidths;
	smallScreenFullWidth?: boolean;
}

interface ActionBarSearchProps extends SearchInputProps {
	filter?: Omit<Filter, 'id'>;
	filterSetter?: FilterSetter;
	canReset?: boolean;
	queryName?: string;
	children?: ReactNode | ReactNode[];
	inModal?: boolean;
}

export const Search: React.FC<React.PropsWithChildren<ActionBarSearchProps>> = (
	props
) => {
	const TEST_ID = 'actionbar.search';
	const qs = useQueryState();
	const queryName = props.queryName || 'search';

	const filter = createFilter(
		props.filter ?? { type: 'text', property: 'name' }
	);

	const handleChange = (value: string) => {
		if (props.filter) {
			props.filterSetter(filter, [value], false, true);
		} else {
			if (value.length > 0) {
				qs.set(queryName, value);
			} else {
				qs.remove(queryName);
			}

			qs.commit();

			if (props.onChange) {
				props.onChange(value);
			}
		}
	};

	return (
		<Fragment>
			<LargeScreen>
				<ActionBarSearch fullWidth={props.inModal}>
					<SearchInput
						{...props}
						small={!props.inModal}
						onChange={handleChange}
						testid={TEST_ID}
					/>
				</ActionBarSearch>
			</LargeScreen>
			<SmallScreen>
				<ActionBarSearch>
					<SearchInput {...props} onChange={handleChange} testid={TEST_ID} />
				</ActionBarSearch>
			</SmallScreen>
		</Fragment>
	);
};

interface PrimaryActionProps {
	children: ReactElement;
}

interface FilterBarProps extends BarProps {
	noBorder?: boolean;
	showChildren?: boolean;
}
export const PrimaryAction: React.FC<
	React.PropsWithChildren<PrimaryActionProps>
> = ({ children }) => children;

// FilterBar will add a toggle for stacked actions beneath the search input on small devices when there's more than one child.
export const FilterBar: React.FC<React.PropsWithChildren<FilterBarProps>> = ({
	maxWidth,
	children,
	noBorder = false,
}) => {
	const [open, setOpen] = useState<boolean>(false);

	const handleOpen = (): void => setOpen((prev: boolean) => !prev);

	const primaryAction = Children.toArray(children).find(
		(child: JSX.Element) => child.type === PrimaryAction
	);

	const actions = Children.toArray(children).filter(
		(child: JSX.Element) => child.type !== PrimaryAction
	);

	return (
		<ActionBarWrapper open={open} noBorder={noBorder} data-testid="actionbar">
			<ContentWrapper maxWidth={maxWidth}>
				<Top hasLeftContent={!!primaryAction}>
					<SmallScreen>
						{primaryAction && primaryAction}
						{actions.length > 1 && (
							<FilterButton active={open} onClick={handleOpen} />
						)}
						{actions.length === 1 && <ActionsWrapper>{actions}</ActionsWrapper>}
					</SmallScreen>
					<LargeScreen>
						{primaryAction && primaryAction}
						{actions.length > 0 && <ActionsWrapper>{actions}</ActionsWrapper>}
					</LargeScreen>
				</Top>
				<SmallScreen>{open && <Bottom>{actions}</Bottom>}</SmallScreen>
			</ContentWrapper>
		</ActionBarWrapper>
	);
};

// FilterBarAction is an action that lives in the action bar, or the page actions in mobile devices.
interface FilterBarAction {
	// If contextMenuItems is set the item will trigger a context menu.
	contextMenuItems?: JSX.Element[];
	href?: string;
	onClick?: () => void;
	icon?: MaterialSymbolVariant;
	label: string;
	type?: ButtonVariant;
	testid?: string;
}

interface IntegratedFilterBarProps extends BarProps {
	/**
	 * Placeholder value for the search bar. Will default to "search"
	 */
	searchPlaceholder?: string;
	/**
	 * The filter definition used for the search value.
	 * Send a pre-configured filter used in `useFilters` in for convenience.
	 */
	searchFilter: Omit<Filter, 'id'>;
	/**
	 * The entire return value from the `useFilters` hook.
	 */
	filters: Filters;
	/**
	 * Actions that will appear on the right side of the action bar, or in the page actions for mobile devices.
	 */
	actions?: FilterBarAction[];
	/**
	 * The icon used for the page actions trigger, will default to "add"
	 */
	pageActionIcon?: MaterialSymbolVariant;
	/**
	 * Let the search input+filter button take up the entire width of the action bar.
	 */
	fullWidth?: boolean;
	/**
	 * Makes spacings and widths look nicer when used in a modal.
	 */
	inModal?: boolean;
}

/**
 * Component that only runs usePageActions so that we can conditionally run it, in case
 * we have the integrated action bar in a modal that shouldn't call usePageActions or similar.
 */
function SetPageActions({
	actions,
	pageActionIcon,
}: {
	actions: FilterBarAction[];
	pageActionIcon?: MaterialSymbolVariant;
}): JSX.Element {
	const pa = [...actions.filter((a) => a)].reverse();
	usePageActions(pa, pageActionIcon);
	return null;
}

// IntegratedFilterBar is a filter bar that handles all presentation and logic around filters and actions.
// Applied filters from the useFilter hook have to be passed in.
export function IntegratedFilterBar({
	maxWidth,
	searchPlaceholder,
	filters,
	searchFilter,
	actions = [],
	pageActionIcon,
	fullWidth,
	inModal,
}: IntegratedFilterBarProps): JSX.Element {
	const activeFilterGroups = Object.entries(filters.activeGroups);
	const ref = useRef<HTMLInputElement>();

	searchFilter = createFilter(searchFilter);

	const getSearchFilterValue = (): string => {
		const _filters = JSON.parse(filters.queryParam.get('filters'));

		const value = _filters
			?.find((item: any) => item.property === searchFilter.property)
			?.values.join(' ');

		return value;
	};

	return (
		<Fragment>
			{actions.length > 0 && (
				<SetPageActions actions={actions} pageActionIcon={pageActionIcon} />
			)}
			<ActionBarWrapper
				open={activeFilterGroups.length > 0}
				inModal={inModal}
				data-testid="actionbar">
				<ContentWrapper maxWidth={maxWidth} inModal={inModal}>
					<Top hasLeftContent>
						<div
							className={cssClasses(
								css.searchWrapper,
								fullWidth && css.fullWidthSearch
							)}>
							<Search
								forwardedRef={ref}
								placeholder={searchPlaceholder || t('Search')}
								filterSetter={filters.setFilter}
								filter={searchFilter}
								value={getSearchFilterValue()}
								inModal={inModal}
							/>
							{filters.Component}
						</div>

						<LargeScreen>
							<ActionsWrapper>
								{actions
									.filter((a) => a)
									.map((action, i) => {
										if (action.contextMenuItems) {
											return (
												<Context.Menu
													key={i}
													toggleWith={
														<Button
															testid={action.testid}
															variant={action.type}
															label={action.label}
															icon={action.icon}
														/>
													}>
													{action.contextMenuItems}
												</Context.Menu>
											);
										} else {
											return (
												<Button
													key={i}
													testid={action.testid}
													variant={action.type}
													href={action.href}
													onClick={action.onClick}
													label={action.label}
													icon={action.icon}
												/>
											);
										}
									})}
							</ActionsWrapper>
						</LargeScreen>
					</Top>
					{activeFilterGroups.length > 0 && (
						<div className={css.appliedFilters}>
							<div
								className={cssClasses(css.appliedFilterButton, css.clearAll)}
								onClick={() => {
									filters.reset();
									ref.current.value = '';
								}}>
								{t('Clear all')}
							</div>

							{activeFilterGroups.map(([label, fg]) => {
								return (
									<div
										key={label}
										className={css.appliedFilterButton}
										onClick={() => {
											filters.clearGroup(fg);

											if (getSearchFilterValue()?.length > 0) {
												ref.current.value = '';
											}
										}}>
										{label} <Icon name="close" />
									</div>
								);
							})}
						</div>
					)}
				</ContentWrapper>
			</ActionBarWrapper>
		</Fragment>
	);
}

// simple layout - (if) label to the left and actions to the right. no toggle or stacking
export const Bar: React.FC<React.PropsWithChildren<BarProps>> = ({
	maxWidth,
	children,
}) => {
	const primaryAction = Children.toArray(children).find(
		(child: JSX.Element) => child.type === PrimaryAction
	);

	const actions = Children.toArray(children).filter(
		(child: JSX.Element) => child.type !== PrimaryAction
	);

	const isSmallScreen: boolean = useMediaQuery({
		maxWidth: styles.breakpoint.toMedium,
	});

	return (
		<ActionBarWrapper open={false} data-testid="actionbar">
			<ContentWrapper maxWidth={maxWidth}>
				<Top hasLeftContent={!!primaryAction && !isSmallScreen}>
					{primaryAction && <LargeScreen>{primaryAction}</LargeScreen>}
					<ActionsWrapper>{actions}</ActionsWrapper>
				</Top>
			</ContentWrapper>
		</ActionBarWrapper>
	);
};

// This is the same as Bar in larger screens but is positioned in the bottom on smaller screens
// This should be combined with the hideTabBar middleware
export const SaveBar: React.FC<React.PropsWithChildren<BarProps>> = ({
	children,
	maxWidth,
}) => {
	const actions = Children.toArray(children).filter(
		(child: JSX.Element) => child.type !== Label
	);

	const { keyboardOpen } = useAppState();

	return (
		<Fragment>
			<LargeScreen>
				<Bar maxWidth={maxWidth}>{children}</Bar>
			</LargeScreen>
			<SmallScreen>
				{!keyboardOpen && (
					<SaveBarWrapper>
						<Top hasLeftContent={false}>
							<ActionsWrapper smallScreenFullWidth>{actions}</ActionsWrapper>
						</Top>
					</SaveBarWrapper>
				)}
			</SmallScreen>
		</Fragment>
	);
};

interface BulkActionsProps {
	numSelected: number;
	showSelected?: boolean;
	setShowSelected?: Dispatch<SetStateAction<boolean>>;

	actionsLabel?: string;
	actions?: ReactNode[];
	children?: ReactNode | ReactNode[];
}

export function BulkActions({
	numSelected,
	showSelected,
	setShowSelected,

	actionsLabel = t('Actions'),
	actions = [],
	children,
}: BulkActionsProps): JSX.Element {
	if (numSelected === 0) {
		return null;
	}

	const handleToggleSelected = () => {
		if (setShowSelected) {
			setShowSelected(!showSelected);
		}
	};

	actions = actions.filter((n) => n);

	return (
		<div className={css.bulkActions}>
			<span>{t('{numSelected} selected', { numSelected })}</span>
			{setShowSelected && (
				<Link onClick={handleToggleSelected}>
					{showSelected ? t('Show all') : t('Show selected')}
				</Link>
			)}
			{children}
			{actions.length > 0 && (
				<Context.Menu
					toggleWith={
						<Button primary small>
							<Row
								autoColumns="auto"
								align="center"
								spacing={styles.spacing._2}>
								<MaterialSymbol variant="settings" />
								{actionsLabel}
							</Row>
						</Button>
					}>
					{actions}
				</Context.Menu>
			)}
		</div>
	);
}
