import { t } from '@transifex/native';
import { Fragment, useState } from 'react';

import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';
import {
	MembershipRole,
	MembershipStatus,
	roleToTranslatedString,
} from 'pkg/api/models/membership';
import { useCurrentOrganization } from 'pkg/identity';
import * as actions from 'pkg/actions';

import TitleModal from 'routes/group/settings/user_management/TitleModal';

import UserActions from 'containers/group/settings/user/Actions';
import AddToGroupsModal from 'containers/user/AddToGroupsModal';

import SectionTitle from 'components/SectionTitle';
import CardAnatomy, * as Card from 'components/Card';
import MissingEntities from 'components/missing-entities';
import Icon from 'components/icon';
import Label from 'components/label';
import * as CardList from 'components/card-list';

import Column from 'components/layout/column';
import Row from 'components/layout/row';

import Button from 'design/button';

interface MembershipItemProps {
	membership: models.membership.Membership;
	refreshMemberships: () => void;
}

function MembershipItem({
	membership,
	refreshMemberships,
}: MembershipItemProps) {
	const [showModal, setShowModal] = useState(false);

	const handleEditTitle = () => setShowModal(true);

	const handleCloseTitleModal = () => setShowModal(false);

	return (
		<Fragment>
			<CardList.Base noBorder key={membership.id}>
				<CardList.IconWrapper>
					<Icon name="nav-badge" fill={styles.palette.blue[500]} />
				</CardList.IconWrapper>
				<CardList.Title>
					<div>{membership.group?.name}</div>
					<CardList.SubTitle>
						{roleToTranslatedString(membership.role as MembershipRole)}
					</CardList.SubTitle>
				</CardList.Title>
				<CardList.Actions>
					<Row autoColumns="max-content" align="center">
						{membership.status === MembershipStatus.Blocked && (
							<Label color="red">{t(`Blocked`)}</Label>
						)}
						{models.membership.isPending(membership) && (
							<Label color="gray">{t('Pending')}</Label>
						)}
						<UserActions
							membership={membership}
							isMembership={true}
							activeView="single"
							onEditTitle={handleEditTitle}
							group={membership.group}
							listTrigger
							refreshMemberships={refreshMemberships}
						/>
					</Row>
				</CardList.Actions>
			</CardList.Base>
			{showModal && (
				<TitleModal membership={membership} onClose={handleCloseTitleModal} />
			)}
		</Fragment>
	);
}

interface GroupsListProps {
	refreshMemberships: () => void;

	memberships: models.membership.Membership[];
	user: models.user.User;
}

export default function GroupsList({
	memberships,
	user,
	refreshMemberships,
}: GroupsListProps) {
	const organizationId = useCurrentOrganization().id;
	const [showModal, setShowModal] = useState(false);

	const handleAddToGroup = () => setShowModal(true);

	const handleCloseModal = () => setShowModal(false);

	const handleAddToGroups = async (groups: { role: number; id: number }[]) => {
		const ok = await actions.memberships.addUserToGroups(user.id, groups);

		if (ok) {
			refreshMemberships();
		}
	};

	let content = (
		<Fragment>
			{memberships.map((membership: models.membership.Membership) => (
				<MembershipItem
					key={membership.id}
					membership={membership}
					refreshMemberships={refreshMemberships}
				/>
			))}
		</Fragment>
	);

	if (!memberships.length) {
		content = (
			<CardAnatomy $noBorder>
				<Card.Body>
					<MissingEntities centered>
						<Icon name="nav-badge" />
						<p>{t(`No groups found`)}</p>
					</MissingEntities>
				</Card.Body>
			</CardAnatomy>
		);
	}

	return (
		<Fragment>
			<Column>
				<SectionTitle icon="groups">
					{t(`Groups`)}
					<Button
						inline
						noPadding
						disabled={user.deletedAt > 0}
						icon="add"
						onClick={handleAddToGroup}>
						{t(`Add to group`)}
					</Button>
				</SectionTitle>
				{content}
			</Column>
			{showModal && (
				<AddToGroupsModal
					groupId={organizationId}
					userId={user.id}
					onConfirm={handleAddToGroups}
					onClose={handleCloseModal}
				/>
			)}
		</Fragment>
	);
}
