// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.V40dq2BKU7fPKv0kK60X {
	padding-top: var(--spacing-8) !important;
	padding-bottom: var(--spacing-8) !important;
}

.SR7c8loWUxsOKhoMava4 {
	color: var(--palette-gray-500);
}

._4SpqZEJMLIQfUk_IGv_A {
	display: grid;
	grid-template-columns: auto 1fr auto;
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/overview/registrations/styles.css"],"names":[],"mappings":"AAAA;CACC,wCAA0C;CAA1C,2CAA0C;AAC3C;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,aAAa;CACb,oCAAoC;AACrC","sourcesContent":[".emptyState {\n\tpadding-block: var(--spacing-8) !important;\n}\n\n.emptyStateIcon {\n\tcolor: var(--palette-gray-500);\n}\n\n.contextItemWithExtraIcon {\n\tdisplay: grid;\n\tgrid-template-columns: auto 1fr auto;\n}\n"],"sourceRoot":""}]);
// Exports
export var emptyState = `V40dq2BKU7fPKv0kK60X`;
export var emptyStateIcon = `SR7c8loWUxsOKhoMava4`;
export var contextItemWithExtraIcon = `_4SpqZEJMLIQfUk_IGv_A`;
export default ___CSS_LOADER_EXPORT___;
