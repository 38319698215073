// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RgYcdTGlJz422RgeqHt7 {
	color: var(--palette-gray-500);
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	font-size: var(--font-size-sm);
}

.dAh8uopxhPuEfUvsRjhl {
	color: var(--palette-gray-500);
	font-size: var(--font-size-sm);
}

.vwi_lLiU12RSUh_nSFAb {
	font-weight: var(--font-weight-semibold);
}
`, "",{"version":3,"sources":["webpack://./routes/forms/single/overview/styles.css"],"names":[],"mappings":"AAAA;CACC,8BAA8B;CAC9B,mBAAO;KAAP,WAAO;SAAP,OAAO;CACP,8BAA8B;AAC/B;;AAEA;CACC,8BAA8B;CAC9B,8BAA8B;AAC/B;;AAEA;CACC,wCAAwC;AACzC","sourcesContent":[".grayed {\n\tcolor: var(--palette-gray-500);\n\tflex: 1;\n\tfont-size: var(--font-size-sm);\n}\n\n.emailStyle {\n\tcolor: var(--palette-gray-500);\n\tfont-size: var(--font-size-sm);\n}\n\n.boldName {\n\tfont-weight: var(--font-weight-semibold);\n}\n"],"sourceRoot":""}]);
// Exports
export var grayed = `RgYcdTGlJz422RgeqHt7`;
export var emailStyle = `dAh8uopxhPuEfUvsRjhl`;
export var boldName = `vwi_lLiU12RSUh_nSFAb`;
export default ___CSS_LOADER_EXPORT___;
