import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import {
	useCurrentAccount,
	useCurrentMembership,
	useCurrentUser,
	useCurrentOrganization,
} from 'pkg/identity';
import * as actions from 'pkg/actions';
import store from 'pkg/store/createStore';
import { pushState } from 'pkg/router/state';
import useConfirm from 'pkg/hooks/useConfirm';
import * as models from 'pkg/api/models';

import Avatar from 'components/avatar';

import * as Drawer from 'design/navigation/drawer';

import * as css from './styles.css';

function UserNavigation(): JSX.Element {
	const activeMembership = useCurrentMembership();
	const membership = useCurrentMembership();
	const account = useCurrentAccount();
	const org = useCurrentOrganization();
	const user = useCurrentUser();

	const onLogout = useConfirm({
		message: t('Are you sure you want to log out?'),
		confirmLabel: t('Log out'),
		onConfirm: () => {
			actions.auth.logout()(store.dispatch);
			pushState(routes.Home());
		},
	});

	let profileRoute: string = null;

	if (user) {
		profileRoute = activeMembership.isOrganizationMembership
			? routes.Profile.Show(org.id, activeMembership.userId)
			: routes.User.Profile.Show(
					org.id,
					activeMembership.groupId,
					activeMembership.userId,
					'overview'
				);
	}

	return (
		<Drawer.ItemGroup>
			<div className={css.user}>
				<div className={css.nameAndTitle}>
					<div>
						{t('Logged in as')} {models.account.fullName(account)}
					</div>
				</div>
			</div>
			<div className={css.itemGroup}>
				{user && (
					<Drawer.Item
						href={profileRoute}
						name={t('Profile')}
						small
						myPages
						avatar={<Avatar size={18} user={user} />}
					/>
				)}
				{(models.membership.isParent(membership) ||
					models.account.isAnAdult(account)) && (
					<Drawer.Item
						href={routes.Account.Settings.Show('children')}
						name={t('My children')}
						icon="supervisor_account"
						small
						myPages
					/>
				)}
				<Drawer.Item
					href={routes.Account.Settings.Show('general')}
					name={t('Account settings')}
					icon="settings"
					small
					myPages
				/>
				{user && (
					<Drawer.Item
						href={routes.UserBilling.Home()}
						name={t('Billing')}
						icon="credit_card"
						small
						myPages
					/>
				)}
				<Drawer.Item
					href={routes.Support()}
					name={t('Support')}
					icon="help"
					small
					myPages
				/>
				<Drawer.Item
					onClick={onLogout}
					name={t('Log out')}
					icon="logout"
					testid="navigation.logout"
					small
					myPages
				/>
			</div>
		</Drawer.ItemGroup>
	);
}

export default UserNavigation;
