import { useT } from '@transifex/react';
import { Fragment, ReactNode } from 'react';

import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentOrganization } from 'pkg/identity';
import { useCollection } from 'pkg/api/use_collection';
import { roleToTranslatedString } from 'pkg/api/models/membership';
import * as routes from 'pkg/router/routes';

import Section from 'components/section';
import * as CardList from 'components/card-list';
import Badge from 'components/Badge';

import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface MembershipsProps {
	user: models.user.User;
}

export default function Memberships({ user }: MembershipsProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const { records: memberships } = useCollection<models.membership.Membership>(
		endpoints.UserGroups.GroupMemberships(org.id, user.id)
	);

	if (!memberships?.length) {
		return null;
	}

	return (
		<Section title={t('Groups')} icon="groups">
			{memberships.map((membership) => (
				<Membership key={membership.id} membership={membership} />
			))}
		</Section>
	);
}

interface MembershipProps {
	membership: models.membership.Membership;
}

function Membership({ membership }: MembershipProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const membershipUrl = membership.isOrganizationMembership
		? routes.Organization.Home(org.id)
		: routes.Group.Show(org.id, membership.groupId);

	const actions: ReactNode[] = [
		<ContextMenu.LinkItem
			key="membership-url"
			href={membershipUrl}
			className={css.contextItemWithExtraIcon}>
			<ContextMenu.ItemIcon name="group" />
			<span>
				{t(
					membership.isOrganizationMembership
						? 'Go to organization'
						: 'Go to group'
				)}
			</span>
			<ContextMenu.ItemIcon name="arrow_forward" />
		</ContextMenu.LinkItem>,
	];

	return (
		<Fragment>
			<CardList.Base noBorder key={membership.id}>
				<CardList.IconWrapper>
					<Badge branded badgeUrl={membership.group?.profileImageUrl} />
				</CardList.IconWrapper>
				<CardList.Title>
					<div>{membership.group?.name}</div>
					<CardList.SubTitle>
						{roleToTranslatedString(
							membership.role as models.membership.MembershipRole
						)}
					</CardList.SubTitle>
				</CardList.Title>
				<CardList.Actions>
					<ContextMenu.Menu
						toggleWith={
							<ContextMenu.TableTrigger>
								<Button secondary icon="more_horiz" />
							</ContextMenu.TableTrigger>
						}>
						{actions}
					</ContextMenu.Menu>
				</CardList.Actions>
			</CardList.Base>
		</Fragment>
	);
}
