import { useT } from '@transifex/react';
import { Fragment, ReactNode } from 'react';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { SubscriptionStatus } from 'pkg/api/models/subscription';
import { useCollection } from 'pkg/api/use_collection';
import { FilterOperator } from 'pkg/filters';
import { useCurrentOrganization } from 'pkg/identity';
import * as json from 'pkg/json';
import * as routes from 'pkg/router/routes';
import useTooltip from 'pkg/hooks/useTooltip';
import { link } from 'pkg/router/utils';
import * as arrays from 'pkg/arrays';
import DateTime from 'pkg/datetime';
import { formatPriceIntervalToText } from 'pkg/utils';
import { formatToCurrency } from 'pkg/i18n/formatters';

import MaterialSymbol from 'components/material-symbols';

import StatusLabel from 'components/payment_platform/status/label';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

import * as css from './styles.css';

function sortSubscriptionsByStatus(
	a: models.subscription.Subscription,
	b: models.subscription.Subscription
) {
	const statusOrder = [
		SubscriptionStatus.Pending,
		SubscriptionStatus.Active,
		SubscriptionStatus.Uncollectible,
		SubscriptionStatus.Completed,
		SubscriptionStatus.Canceled,
		SubscriptionStatus.Trialing,
		SubscriptionStatus.Scheduled,
		SubscriptionStatus.Unknown,
	];

	return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
}

interface SubscriptionsProps {
	user: models.user.User;
}

export default function Subscriptions({
	user,
}: SubscriptionsProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const { records, isLoading } =
		useCollection<models.subscription.Subscription>(
			endpoints.Subscriptions.Index(),
			{
				queryParams: new URLSearchParams({
					group_id: org.id.toString(),
					associated_user_id: user.id.toString(),
					filters: json.stringify([
						{
							property: 'status',
							operator: FilterOperator.Includes,
							values: [
								SubscriptionStatus.Active,
								SubscriptionStatus.Canceled,
								SubscriptionStatus.Trialing,
								SubscriptionStatus.Completed,
								SubscriptionStatus.Uncollectible,
								SubscriptionStatus.Unknown,
							],
						},
					]),
				}),
			}
		);

	const subscriptions = records.sort(sortSubscriptionsByStatus);

	const columns: Table.HeaderColumn[] = [
		{ content: t('Name'), align: 'left' },
		{ content: t('Amount'), align: 'right', width: 'max-content' },
		{ content: t('Status'), width: 'max-content', align: 'center' },
		{ content: t('Billed to') },
		{ content: t('Products for') },
		{ content: t('Created at'), align: 'right' },
		{ content: '', width: 'max-content' },
	];

	const emptyState = {
		title: t('No subscriptions found'),
		content: t('There are no subscriptions associated with {name}.', {
			name: models.user.fullName(user),
		}),
		image: (
			<MaterialSymbol
				actualSize
				scale={3}
				variant="currency_exchange"
				className={css.emptyStateIcon}
			/>
		),

		className: css.emptyState,
	};

	return (
		<Table.Table
			columns={columns}
			isLoading={isLoading}
			emptyState={emptyState}>
			{subscriptions.map((subscription) => (
				<Subscription
					key={subscription.id}
					subscription={subscription}
					user={user}
				/>
			))}
		</Table.Table>
	);
}

interface SubscriptionProps {
	user: models.user.User;
	subscription: models.subscription.Subscription;
}

function Subscription({ user, subscription }: SubscriptionProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const { recurringInterval, recurringIntervalCount } = subscription;

	const productRows = models.subscription.findProductRows(subscription);
	const products = productRows.map((r) => r.productPrice.product);

	let productName: string = products[0].name || '—';

	if (products.length > 1) {
		productName = t(`{productName} and {count} more...`, {
			productName,
			count: products.length - 1,
		});
	}

	const cost = models.subscription.getSubscriptionRowsTotalCost(
		subscription.rows
	);

	const amount: string = formatPriceIntervalToText(
		recurringInterval,
		recurringIntervalCount
	);

	const createdAt: string = DateTime.fromTimestamp(
		subscription.createdAt
	).toLocaleDateString();

	const users: models.user.User[] = subscription.rows
		.filter((sr) => sr?.user)
		.map((sr) => sr.user);

	const usersNamesList = arrays.penultimateJoin(
		users.map((user) => models.user.fullName(user)),
		', ',
		t('and')
	);

	const subscriptionUrl = link(
		routes.Subscription.Show(org.id, subscription.id, 'overview'),
		{
			return_url: routes.Profile.Show(org.id, user.id),
		}
	);

	const { tooltip, onMouseEnter } = useTooltip(usersNamesList);

	let productsForUsers: ReactNode = <span>—</span>;

	if (users.length === 1) {
		productsForUsers = <span>{models.user.fullName(users[0])}</span>;
	} else if (users.length > 1) {
		productsForUsers = (
			<Fragment>
				{tooltip}
				<span onMouseEnter={onMouseEnter}>
					{t('{name} and {num} more', {
						name: models.user.fullName(users[0]),
						num: users.length - 1,
						_comment: 'multiple billed to users',
					})}
				</span>
			</Fragment>
		);
	}

	const billedTo: string = subscription.customerUser
		? models.user.fullName(subscription.customerUser)
		: '—';

	const actions: ReactNode[] = [
		<ContextMenu.LinkItem
			key="subscription-url"
			href={subscriptionUrl}
			className={css.contextItemWithExtraIcon}>
			<ContextMenu.ItemIcon name="currency_exchange" />
			<span>{t('View subscription')}</span>
			<ContextMenu.ItemIcon name="arrow_forward" />
		</ContextMenu.LinkItem>,
	];

	return (
		<Fragment>
			<Table.Row>
				<Table.LinkCell href={subscriptionUrl}>{productName}</Table.LinkCell>
				<Table.LinkCell href={subscriptionUrl} align="right">
					{formatToCurrency(cost, subscription.currency)} {amount}
				</Table.LinkCell>
				<Table.LinkCell href={subscriptionUrl}>
					<StatusLabel status={models.subscription.getStatus(subscription)} />
				</Table.LinkCell>
				<Table.Cell>{billedTo}</Table.Cell>
				<Table.LinkCell href={subscriptionUrl}>
					{productsForUsers}
				</Table.LinkCell>
				<Table.Cell align="right">{createdAt}</Table.Cell>
				<Table.ActionCell>
					<ContextMenu.Menu
						toggleWith={
							<ContextMenu.TableTrigger>
								<MaterialSymbol actualSize variant="more_horiz" scale={1.3} />
							</ContextMenu.TableTrigger>
						}>
						{actions}
					</ContextMenu.Menu>
				</Table.ActionCell>
			</Table.Row>
		</Fragment>
	);
}
