import { t } from '@transifex/native';
import { ChangeEvent, Fragment, useRef, useState } from 'react';

import * as endpoints from 'pkg/api/endpoints/auto';
import Link from 'pkg/router/Link';
import { useCurrentMembership } from 'pkg/identity';
import * as models from 'pkg/api/models';
import { PaymentProvider } from 'pkg/api/models/group';
import { useEndpoint } from 'pkg/api/use_endpoint';

import EditableRow from 'routes/group/settings/editable-row';

import CardAnatomy, * as Card from 'components/Card';
import SectionTitle from 'components/SectionTitle';

import Column from 'components/layout/column';
import {
	SettingDescription,
	SettingLabel,
	SettingRow,
	SettingRowDivider,
} from 'components/settings/Settings';
import * as Input from 'components/form/inputs';
import TextInput from 'components/form/TextInput';

import Button from 'design/button';

import * as css from './styles.css';

interface PaymentsSettingsProps {
	group: models.group.Group;
	refresh: () => void;
}

export default function PaymentsSettings({
	group,
	refresh,
}: PaymentsSettingsProps): JSX.Element {
	const [serviceFee, setServiceFee] = useState<string>(
		group.serviceFeePercent.toString() || '0'
	);
	const [isEditingServiceFee, setIsEditingServiceFee] = useState(false);

	const [hasError, setHasError] = useState(false);
	const handleIsEditingServiceFee = () => {
		setIsEditingServiceFee(true);
	};
	const handleNameCancel = () => {
		setIsEditingServiceFee(false);
		setServiceFee(group.serviceFeePercent.toString());
		setHasError(false);
	};
	const serviceFeeRef = useRef(null);

	const handleChangeServiceFee = (event: ChangeEvent<HTMLInputElement>) => {
		if (
			Number.parseFloat(event.currentTarget.value) > 100 ||
			Number.parseFloat(event.currentTarget.value) < 0 ||
			!event.currentTarget.value
		) {
			setHasError(true);
		} else {
			setHasError(false);
		}
		setServiceFee(event.currentTarget.value);
	};

	const handleUpdateServiceFee = async () => {
		await models.update(group, {
			serviceFeePercent: Number.parseFloat(serviceFee),
		});
		refresh();
		setIsEditingServiceFee(false);
	};

	const { record: providerSettings } =
		useEndpoint<models.providerSettings.ProviderSettings>(
			endpoints.Groups.PaymentProviderSettings(group.id)
		);
	const membership = useCurrentMembership();
	if (
		!group.paymentProviderActive ||
		!models.membership.hasCapability(
			membership,
			models.membership.Capabilities.PaymentAdmin
		)
	) {
		return null;
	}
	return (
		<Column>
			<SectionTitle>{t('Payments')}</SectionTitle>
			<CardAnatomy $noBorder>
				<Card.Body>
					<SettingRow>
						<SettingLabel>{t('Payment Provider')}</SettingLabel>
						<SettingDescription>
							{group.paymentProvider === PaymentProvider.Stripe && t('Stripe')}
							{group.paymentProvider === PaymentProvider.Purspot &&
								t('Purspot')}
							{group.paymentProvider === PaymentProvider.Nuvei && t('Nuvei')}
							{group.paymentProvider === PaymentProvider.Adyen && t('Adyen')}
						</SettingDescription>
						{group.paymentProvider === PaymentProvider.Stripe && (
							<Link href="https://dashboard.stripe.com/" target="_blank">
								<Button small icon="new_window">
									{t('Visit stripe dashboard')}
								</Button>
							</Link>
						)}
					</SettingRow>
					{providerSettings.canHaveServiceFee && (
						<Fragment>
							<SettingRowDivider />
							<EditableRow
								label={t('Service fee')}
								isEditing={isEditingServiceFee}
								staticComponent={serviceFee + '%'}
								hasError={hasError}
								editableComponent={
									<Fragment>
										<Input.Group hasError={hasError}>
											<TextInput
												ref={serviceFeeRef}
												value={serviceFee}
												onChange={handleChangeServiceFee}
												hasError={hasError}
												type="number"
												max={100}
												autoFocus
											/>
										</Input.Group>
										<div className={css.serviceFeeDescription}>
											{t(
												'Add a percentage service fee to all transactions in the organization. This will be added on top of the overall order value.'
											)}
										</div>
									</Fragment>
								}
								onEdit={handleIsEditingServiceFee}
								onSubmit={handleUpdateServiceFee}
								onCancel={handleNameCancel}
							/>
						</Fragment>
					)}
				</Card.Body>
			</CardAnatomy>
		</Column>
	);
}
