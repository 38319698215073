import { useT } from '@transifex/react';

import * as flashActions from 'pkg/actions/flashes';

import * as routes from 'pkg/router/routes';

import { FormSummary } from 'routes/forms/single/overview';

import Row from 'components/layout/row';
import * as ActionBar from 'components/layout/ActionBar';

import Button from 'design/button';

interface Props {
	orgId: number;
	summary: FormSummary;
}

const FormOverviewActionBar = ({ orgId, summary }: Props) => {
	const t = useT();

	const handleCopyUrl = async () => {
		await navigator.clipboard.writeText(summary.Form.publicUrl);
		flashActions.show({ title: t('URL copied') });
	};

	return (
		<ActionBar.Bar>
			<Row autoColumns="max-content">
				<Button icon="link" onClick={handleCopyUrl}>
					{t('Copy registration URL')}
				</Button>
				<Button
					icon="arrow_forward"
					href={routes.Registration.Preview(summary.Form.guid)}>
					{t('Visit registration')}
				</Button>
				<Button
					icon="edit"
					href={routes.Registrations.Edit(
						orgId,
						summary.Form.groupId,
						summary.Form.id
					)}>
					{t('Edit')}
				</Button>
			</Row>
		</ActionBar.Bar>
	);
};

export default FormOverviewActionBar;
