import { Fragment, ReactNode, useState } from 'react';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { useCollection } from 'pkg/api/use_collection';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as ls from 'pkg/local_storage';
import { useCurrentOrganization } from 'pkg/identity';

import Registration from 'routes/user/registrations/open/registration';
import * as css from 'routes/user/registrations/categories/styles.css';

import SectionTitle from 'components/SectionTitle';
import BadgeWrapper from 'components/badge-wrapper';
import Badge from 'components/Badge';
import { ListLayout, useViewToggle } from 'components/view-toggle';

import Column from 'components/layout/column';
import SearchInput from 'components/form/SearchInput';
import { Spinner } from 'components/loaders/spinner';
import Row from 'components/layout/row';
import FormsGridItem from 'components/forms/grid-item';

import MissingEntities from 'design/placeholders/block';
import Divider from 'design/divider';

const renderRegistrations = (
	records: models.form.Form[],
	group: models.group.Group,
	t: any
) => {
	const categories: string[] = [];

	records.map((form: models.form.Form) => {
		if (
			form.formCategory &&
			categories.indexOf(form.formCategory.title) === -1
		) {
			return categories.push(form.formCategory.title);
		}
	});

	categories.sort();

	const rendered: JSX.Element[] = [];

	categories.map((categoryName: string) => {
		const registrations = records
			.filter(
				(form: models.form.Form) =>
					form.formCategory && categoryName === form.formCategory.title
			)
			.sort((a: models.form.Form, b: models.form.Form) =>
				a.title.localeCompare(b.title)
			)
			.map((form: models.form.Form) => {
				return <Registration key={form.id} form={form} group={group} />;
			});

		rendered.push(
			<Column spacing={styles.spacing._3} key={categoryName}>
				<SectionTitle thin>{categoryName}</SectionTitle>
				{registrations}
			</Column>
		);
	});

	const registrationsWithoutCategory = records.filter(
		(form: models.form.Form) => !form.formCategory
	);

	if (registrationsWithoutCategory.length) {
		rendered.push(
			<Column spacing={styles.spacing._3} key="no-category">
				<SectionTitle thin>{t('No Category')}</SectionTitle>
				{registrationsWithoutCategory
					.sort((a: models.form.Form, b: models.form.Form) =>
						a.title.localeCompare(b.title)
					)
					.map((form: models.form.Form) => {
						return <Registration key={form.id} form={form} group={group} />;
					})}
			</Column>
		);
	}

	return rendered;
};

const filterSearch = (records: models.form.Form[], search: string) => {
	const searchBy = search.toLowerCase();

	return records
		.filter((form: models.form.Form) => {
			let searchable = `${form.title.toLowerCase()} ${form.description.toLowerCase()}`;

			if (form.submissions) {
				form.submissions
					.filter((submission) => !!submission.submittedForUser)
					.map((submission: models.formSubmission.FormSubmission) => {
						searchable += submission.submittedForUser.firstName.toLowerCase();
						searchable += submission.submittedForUser.lastName.toLowerCase();
					});
			}

			if (searchable.includes(searchBy)) {
				return true;
			}
		})
		.sort((a: models.form.Form, b: models.form.Form) =>
			a.title.localeCompare(b.title)
		);
};

export default function OpenRegistrations({}) {
	const t = useT();
	const viewToggle = useViewToggle({
		localStorageKey: ls.LocalStorageKeys.FormCategoriesView,
	});

	const [search, setSearch] = useState('');
	const org = useCurrentOrganization();

	const { records, isLoading } = useCollection<models.form.Form>(
		endpoints.Organizations.ListForms(org.id)
	);

	const handleChange = (value: string) => {
		setSearch(value);
	};

	let searchRegistrations: models.form.Form[] = [];

	if (search) {
		searchRegistrations = filterSearch(records, search);
	}

	const recordsToMap = search ? searchRegistrations : records;

	let content: ReactNode = (
		<div className={css.grid}>
			{recordsToMap.map((r) => (
				<FormsGridItem key={r.id} form={r} group={org} />
			))}
		</div>
	);

	if (viewToggle.view === ListLayout.List) {
		content = search ? (
			<Fragment>
				{searchRegistrations.map((f) => (
					<Registration key={f.id} form={f} group={org} />
				))}
			</Fragment>
		) : (
			<Fragment>{renderRegistrations(records, org, t)}</Fragment>
		);
	}

	if (search && searchRegistrations.length === 0) {
		content = (
			<MissingEntities
				noBorder
				title={t('No results')}
				description={t('No search results.')}
			/>
		);
	}

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<Column spacing={styles.spacing._6}>
			<Row autoColumns="max-content" justifyContent="space-between">
				<Row autoColumns="max-content" align="center">
					<BadgeWrapper>
						<Badge badgeUrl={org.profileImageUrl} />
					</BadgeWrapper>
					<span className={css.org}>{org.name}</span>
				</Row>
				{viewToggle.component}
			</Row>
			<Divider />

			<div>{t('Please select a registration below:')}</div>
			<SearchInput placeholder={t('Search')} onChange={handleChange} />
			{content}
		</Column>
	);
}

interface DashboardRegistrationsProps {
	showTitle?: boolean;
}

export function DashboardRegistrations({
	showTitle,
}: DashboardRegistrationsProps): JSX.Element {
	const t = useT();

	const [search, setSearch] = useState('');
	const org = useCurrentOrganization();

	const { records, isLoading } = useCollection<models.form.Form>(
		endpoints.Organizations.ListForms(org.id)
	);

	if (isLoading) {
		return <Spinner />;
	}

	let searchRegistrations: JSX.Element[] = [];

	if (search) {
		searchRegistrations = filterSearch(records, search).map(
			(form: models.form.Form) => (
				<Registration key={form.id} form={form} group={org} />
			)
		);

		if (searchRegistrations.length === 0) {
			searchRegistrations = [
				<MissingEntities
					noBorder
					title={t('No results')}
					description={t('No search results.')}
				/>,
			];
		}
	}

	const handleSearch = (value: string) => {
		setSearch(value);
	};

	const hasRegistrations = records.length > 0;

	return (
		<Column spacing={styles.spacing._5}>
			{showTitle && <SectionTitle>{t('Registrations')}</SectionTitle>}
			{hasRegistrations && (
				<SearchInput onChange={handleSearch} placeholder={t('Search')} />
			)}
			{hasRegistrations ? (
				<Column spacing={styles.spacing._7}>
					{search ? (
						<Column spacing={styles.spacing._3}>{searchRegistrations}</Column>
					) : (
						renderRegistrations(records, org, t)
					)}
				</Column>
			) : (
				<MissingEntities
					noBorder
					title={t('No open registrations')}
					description={t(
						'There are no open registrations published by the club.'
					)}
					icon="registration"
				/>
			)}
		</Column>
	);
}
