import styled, { css } from 'styled-components';

import * as styles from 'pkg/config/styles';

export const Layout = styled.div`
	height: 100%;

	@media not ${styles.breakpoint.small} {
		display: grid;
		grid-template-columns: auto 1fr;
		overflow: hidden;
	}
`;

export const SideBar = styled.div`
	background-color: var(--palette-white);
	overflow: auto;
	word-break: break-all;
	max-height: 100%;
	min-height: 100%;

	@media not ${styles.breakpoint.small} {
		border-right: 1px solid var(--palette-gray-300);
		width: 300px;
	}

	/* Hide sidebar when it is empty (or containing empty nodes) */
	&[data-hide-if-empty]:empty,
	&[data-hide-if-empty]:has(> *:empty) {
		width: 0px;
	}
`;

export const SideBarBody = styled.div`
	padding: var(--spacing-8) var(--spacing-6);
`;

// Content is a scrollable area used to wrap all content outside the sidebar.
export const Content = styled.div<{
	backgroundColor?: string;
	hideOverflow?: boolean;
}>`
	max-height: 100%;
	min-height: 100%;
	min-width: 0;
	overflow: auto;

	${({ hideOverflow }) =>
		hideOverflow &&
		css`
			height: 100%;
			overflow: hidden;
		`};

	${({ backgroundColor }) =>
		backgroundColor &&
		css`
			background-color: ${backgroundColor};
		`}
`;

// Table is used instead of Content to wrap tables in a non-scrollable area. Scroll is instead handled by the table.
export const Table = styled.div`
	display: flex;
	flex-flow: column nowrap;
	max-height: 100%;
	min-height: 100%;
	min-width: 0;
	overflow: hidden;
`;

export const TopWrapper = styled.div`
	text-align: center;
	padding: var(--spacing-6);
	padding-top: var(--spacing-8);
`;

export const AvatarWrapper = styled.div`
	display: flex;
	justify-content: center;
`;
