import * as models from 'pkg/api/models';

interface OrganizationUserProfileVideosProps {
	user: models.user.User;
}

export default function OrganizationUserProfileVideos({
	user,
}: OrganizationUserProfileVideosProps): JSX.Element {
	return <p>Showing Videos for {models.user.fullName(user)}</p>;
}
