import { t } from '@transifex/native';
import { useEffect } from 'react';

import * as routes from 'pkg/router/routes';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentRoute } from 'pkg/router/hooks';
import { useEndpoint } from 'pkg/api/use_endpoint';

import { ShowFormResponse } from 'routes/forms/registration/preview';

import StatusScreen from 'components/status-screen';

import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

interface FailedProps {
	setPageTitle: (title: string) => void;
}

export default function Failed({ setPageTitle }: FailedProps) {
	const route = useCurrentRoute();

	const guid = route.guid;

	const { record: response, isLoading } = useEndpoint<ShowFormResponse>(
		endpoints.Forms.PublicShow(guid)
	);

	useEffect(() => {
		if (!isLoading && response) {
			setPageTitle(response.form.title);
		}
	}, [isLoading]);

	if (isLoading) {
		return <Spinner />;
	}

	return (
		<StatusScreen
			fullscreen
			status="error"
			title={t('Form submission failed!')}
			description={t(
				'The form submission failed leading to your response not being recorded. Please try again and if the problem persists contact 360Player for assistance.'
			)}>
			<Button primary large href={routes.Registration.Preview(guid)}>
				{t('Back to registration')}
			</Button>
		</StatusScreen>
	);
}
