import { Author } from 'pkg/api/models/author';
import { Dateable } from 'pkg/api/models/dateable';
import { Event } from 'pkg/api/models/event';
import { Linkable } from 'pkg/api/models/linkable';
import { Record } from 'pkg/api/models/record';
import { SessionBlock } from 'pkg/api/models/session_block';
import { Tag } from 'pkg/api/models/tag';

export interface Session extends Record, Linkable, Dateable {
	accountId: number;
	eventId: number;
	sportId?: number;
	title: string;
	description: string;

	defaultLanguage: string;
	playerVisible: boolean;
	userCreated: boolean;
	deletedAt: number;

	event?: Event;
	tags: Tag[];
	blocks?: SessionBlock[];

	author?: Author;
}

export function isNew(session: Session): boolean {
	return session.createdAt + 3600 * 24 > Math.floor(Date.now() / 1000);
}

export function duration(session: Session): number {
	return session?.blocks?.reduce((n, { duration }) => n + duration, 0) || 0;
}

export function numBlocks(session: Session): number {
	return session?.blocks?.length || 0;
}

export function numExercises(session: Session): number {
	let exerciseIds: number[] = [];

	session?.blocks?.forEach((block) => {
		if (block?.items?.length > 0) {
			exerciseIds = [...exerciseIds, ...block.items.map((i) => i.exerciseId)];
		}
	});

	return [...new Set(exerciseIds)].length;
}
