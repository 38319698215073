// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JX33OTRWcFQ_mxmnxQcQ {
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: minmax(0, 1fr);
	grid-gap: var(--spacing-3);
	gap: var(--spacing-3);
	padding: var(--spacing-5);
	padding-top: 0;
}
@media (max-width: 768px) {
	.JX33OTRWcFQ_mxmnxQcQ {
		border-bottom: 1px solid var(--palette-gray-300);
}
	}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/user-actions/styles.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,sBAAsB;CACtB,iCAAiC;CACjC,0BAAqB;CAArB,qBAAqB;CACrB,yBAAyB;CACzB,cAAc;AAKf;AAHC;CARD;EASE,gDAAgD;AAElD;CADC","sourcesContent":[".wrapper {\n\tdisplay: grid;\n\tgrid-auto-flow: column;\n\tgrid-auto-columns: minmax(0, 1fr);\n\tgap: var(--spacing-3);\n\tpadding: var(--spacing-5);\n\tpadding-top: 0;\n\n\t@media (--small-viewport) {\n\t\tborder-bottom: 1px solid var(--palette-gray-300);\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `JX33OTRWcFQ_mxmnxQcQ`;
export default ___CSS_LOADER_EXPORT___;
