import React, {
	Fragment,
	useState,
	cloneElement,
	useMemo,
	ReactNode,
	ReactElement,
} from 'react';
import styled from 'styled-components';
import { ErrorBoundary } from '@sentry/react';

import * as styles from 'pkg/config/styles';

import { useCurrentOrganization } from 'pkg/identity';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import SmallScreenContent from 'components/layout/SmallScreenContent';
import * as LargeLayout from 'components/navigation/header/large_screen/Styles';
import * as SmallLayout from 'components/navigation/header/small_screen/Styles';
import { ViewCrash } from 'components/crashes/view-crash';

export interface SubNavItemProps {
	title: string;
	replace?: boolean;
	href?: string;
	to?: string;
	params?: any;
	query?: any;
	onClick?: any;
	testid?: string;
}

export interface PageProps {
	title?: string;
	icon?: MaterialSymbolVariant;
	subNavItems?: SubNavItemProps[];
	backgroundColor?: string;
	children?: ReactNode;
	currentTab?: string;
	maxWidth?: styles.size.PageWidths;
	compactHeader?: boolean;
	subTitle?: string;
}

export interface ViewSettings {
	setPageTitle?: React.Dispatch<React.SetStateAction<string>>;
	setPageIcon?: React.Dispatch<React.SetStateAction<MaterialSymbolVariant>>;
	setPageSubTitle?: React.Dispatch<React.SetStateAction<string>>;
	setPageActions?: React.Dispatch<any>;
	setPageSubNavItems?: React.Dispatch<React.SetStateAction<SubNavItemProps[]>>;
	setPageBackgroundColor?: React.Dispatch<React.SetStateAction<string>>;
	setNoScroll?: React.Dispatch<React.SetStateAction<boolean>>;
	currentTab?: string;
}

interface NavItemsProps {
	items: SubNavItemProps[];
}

export const LargeSubNavItems = ({ items }: NavItemsProps) => {
	const org = useCurrentOrganization();

	if (items?.length <= 1) {
		return null;
	}

	return (
		<Fragment>
			{items.map((item: SubNavItemProps, i) => {
				const orgParam = org?.id ? { organizationId: org.id } : null;
				const params = { ...item.params, ...orgParam };

				return (
					<LargeLayout.SubNavItem
						key={`${item.title}-${i}`}
						replace={item.replace}
						href={item.href}
						to={item.to}
						params={params}
						query={item.query}
						onClick={item.onClick}
						data-testid={item.testid}>
						{item.title}
					</LargeLayout.SubNavItem>
				);
			})}
		</Fragment>
	);
};

export const SmallSubNavItems = ({ items }: NavItemsProps) => {
	const org = useCurrentOrganization();

	if (items?.length <= 1) {
		return null;
	}

	return (
		<Fragment>
			{items.map((item: SubNavItemProps, i) => {
				const orgParam = org?.id ? { organizationId: org.id } : null;
				const params = { ...item.params, ...orgParam };

				return (
					<SmallLayout.SubNavItem
						key={`${item.title}-${i}`}
						replace={item.replace}
						href={item.href}
						to={item.to}
						query={item.query}
						onClick={item.onClick}
						params={params}
						data-testid={item.testid}>
						{item.title}
					</SmallLayout.SubNavItem>
				);
			})}
		</Fragment>
	);
};

export const FormPage = ({ title, icon, children }: PageProps) => {
	const [pageTitle, setPageTitle] = useState(title);
	const [pageIcon, setPageIcon] = useState(icon as MaterialSymbolVariant);
	const [pageActions, setPageActions] = useState(null);

	children = useMemo(
		() =>
			React.Children.map(children, (c: ReactElement) =>
				cloneElement(c, {
					setPageTitle,
					setPageIcon,
					setPageActions,
				})
			),
		[children]
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader
					title={pageTitle}
					icon={pageIcon}></LargeScreenHeader>

				<LargeScreenContent.Wrapper backgroundColor={styles.palette.white}>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent formPage backgroundColor={styles.palette.white}>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

export const Page = ({
	title,
	icon,
	subNavItems = [],
	backgroundColor,
	children,
	currentTab,
	subTitle,
}: PageProps) => {
	const [pageTitle, setPageTitle] = useState(title);
	const [pageIcon, setPageIcon] = useState(icon as MaterialSymbolVariant);
	const [pageSubTitle, setPageSubTitle] = useState(subTitle);
	const [pageActions, setPageActions] = useState(null);
	const [pageSubNavItems, setPageSubNavItems] = useState(subNavItems);
	const [pageBackgroundColor, setPageBackgroundColor] =
		useState(backgroundColor);
	const [noScroll, setNoScroll] = useState(false);

	const hasSubNav = pageSubNavItems.length > 1;

	children = useMemo(
		() =>
			React.Children.map(children, (c: ReactElement) =>
				cloneElement(c, {
					setPageTitle,
					setPageIcon,
					setPageSubTitle,
					setPageActions,
					setPageSubNavItems,
					setPageBackgroundColor,
					setNoScroll,
				})
			),
		[children]
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{hasSubNav && <LargeSubNavItems items={pageSubNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper
					key={currentTab}
					backgroundColor={pageBackgroundColor}>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader
					subTitle={pageSubTitle}
					title={pageTitle}
					actionTrigger={pageActions}
				/>

				<SmallScreenContent
					backgroundColor={pageBackgroundColor}
					noScroll={noScroll}>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={pageSubNavItems} />
						</SmallLayout.SubNav>
					)}
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

export const SimplePage = ({
	title,
	icon,
	subNavItems = [],
	children,
	backgroundColor,

	currentTab,
}: PageProps) => {
	const [pageTitle, setPageTitle] = useState(title);
	const [pageIcon, setPageIcon] = useState(icon as MaterialSymbolVariant);
	const [pageActions, setPageActions] = useState(null);
	const [pageSubNavItems, setPageSubNavItems] = useState(subNavItems);
	const [pageBackgroundColor, setPageBackgroundColor] =
		useState(backgroundColor);

	const hasSubNav = subNavItems.length > 0;

	children = useMemo(
		() =>
			React.Children.map(children, (c: ReactElement) =>
				cloneElement(c, {
					setPageTitle,
					setPageIcon,
					setPageActions,
					setPageSubNavItems,
					setPageBackgroundColor,
				})
			),
		[children]
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{hasSubNav && <LargeSubNavItems items={pageSubNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper
					key={currentTab}
					backgroundColor={pageBackgroundColor}>
					<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.STANDARD}>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent backgroundColor={pageBackgroundColor}>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={pageSubNavItems} />
						</SmallLayout.SubNav>
					)}
					<LargeScreenContent.Inner>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</LargeScreenContent.Inner>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

export const WidePage = ({
	title,
	icon,
	subNavItems = [],
	maxWidth = styles.size.PageWidths.WIDE,
	children,
	currentTab,
}: PageProps) => {
	const [pageTitle, setPageTitle] = useState(title);
	const [pageIcon, setPageIcon] = useState(icon as MaterialSymbolVariant);
	const [pageActions, setPageActions] = useState(null);

	const hasSubNav = subNavItems.length > 0;

	children = useMemo(
		() =>
			React.Children.map(children, (c: ReactElement) =>
				cloneElement(c, {
					setPageTitle,
					setPageIcon,
					setPageActions,
				})
			),
		[children]
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{hasSubNav && <LargeSubNavItems items={subNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper key={currentTab}>
					<LargeScreenContent.Inner maxWidth={maxWidth}>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</LargeScreenContent.Inner>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={subNavItems} />
						</SmallLayout.SubNav>
					)}
					<LargeScreenContent.Inner>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</LargeScreenContent.Inner>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

// FullScreenPage locks scrolling, and removes content padding in mobile.
export const FullScreenPage = ({
	title,
	icon,
	subNavItems = [],
	children,
	currentTab,
}: PageProps) => {
	const [pageTitle, setPageTitle] = useState(title);
	const [pageIcon, setPageIcon] = useState(icon as MaterialSymbolVariant);
	const [pageActions, setPageActions] = useState(null);

	const hasSubNav = subNavItems.length > 0;

	children = useMemo(
		() =>
			React.Children.map(children, (c: ReactElement) =>
				cloneElement(c, {
					setPageTitle,
					setPageIcon,
					setPageActions,
				})
			),
		[children]
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{hasSubNav && <LargeSubNavItems items={subNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper key={currentTab} disableScroll>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent noScroll>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={subNavItems} />
						</SmallLayout.SubNav>
					)}
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

// FullScreenPageWithoutBorderRadius removes content padding in mobile.
export const FullScreenPageWithoutBorderRadius = ({
	title,
	icon,
	compactHeader,
	subNavItems = [],
	children,
	currentTab,
}: PageProps) => {
	const [pageTitle, setPageTitle] = useState(title);
	const [pageIcon, setPageIcon] = useState(icon as MaterialSymbolVariant);
	const [pageActions, setPageActions] = useState(null);
	const [pageSubNavItems, setPageSubNavItems] = useState(subNavItems);

	const hasSubNav = subNavItems.length > 0;

	children = useMemo(
		() =>
			React.Children.map(children, (c: ReactElement) =>
				cloneElement(c, {
					setPageTitle,
					setPageIcon,
					setPageActions,
					setPageSubNavItems,
				})
			),
		[children]
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader
					title={pageTitle}
					icon={pageIcon}
					compact={!!compactHeader}>
					{hasSubNav && <LargeSubNavItems items={pageSubNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper key={currentTab}>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent noBorderRadius>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={pageSubNavItems} />
						</SmallLayout.SubNav>
					)}
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

export const FullScreenPageWithoutHeader = ({ children }: PageProps) => {
	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenContent.Wrapper backgroundColor={styles.palette.white}>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenContent noBorderRadius noPadding>
					<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

export const TableContentWrapper = styled.div`
	display: flex;
	flex-flow: column nowrap;
	max-height: 100%;
	min-height: 100%;
`;

// Used when full screen tables need sticky header and footer
export const FullScreenTablePage = ({
	title,
	icon,
	subNavItems = [],
	children,
	currentTab,
}: PageProps) => {
	const [pageTitle, setPageTitle] = useState(title);
	const [pageIcon, setPageIcon] = useState(icon as MaterialSymbolVariant);
	const [pageActions, setPageActions] = useState(null);
	const [pageSubNavItems, setPageSubNavItems] = useState(subNavItems);

	const hasSubNav = pageSubNavItems.length > 0;

	children = useMemo(
		() =>
			React.Children.map(children, (c: ReactElement) =>
				cloneElement(c, {
					setPageTitle,
					setPageIcon,
					setPageActions,
					setPageSubNavItems,
				})
			),
		[children]
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{hasSubNav && <LargeSubNavItems items={pageSubNavItems} />}
				</LargeScreenHeader>

				<LargeScreenContent.Wrapper key={currentTab} disableScroll>
					<TableContentWrapper>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</TableContentWrapper>
				</LargeScreenContent.Wrapper>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={pageTitle} actionTrigger={pageActions} />

				<SmallScreenContent noScroll>
					{hasSubNav && (
						<SmallLayout.SubNav>
							<SmallSubNavItems items={pageSubNavItems} />
						</SmallLayout.SubNav>
					)}
					<TableContentWrapper>
						<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
					</TableContentWrapper>
				</SmallScreenContent>
			</SmallScreen>
		</Fragment>
	);
};

export const PrintablePage = ({ children }: PageProps) => {
	return (
		<Fragment>
			<ErrorBoundary fallback={<ViewCrash />}>{children}</ErrorBoundary>
		</Fragment>
	);
};
