// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JDC70P8AJTcXiOr8pTFy {
	max-width: var(--page-width-wide);
	width: 100%;
	padding: var(--spacing-8) var(--spacing-7) 0 var(--spacing-7);
	margin: 0 auto;
}

@media (max-width: 768px) {

.JDC70P8AJTcXiOr8pTFy {
		padding: var(--spacing-4) 0 var(--spacing-8) 0;
}
	}

.lpnrNXIRM6KPa9v18Fbi {
	font-size: var(--font-size-3xl);
	line-height: var(--font-line-height-2xl);
	font-weight: var(--font-weight-semibold);
}

.wZNQUa3o7a8uDsVp9KF8 {
	font-size: var(--font-size-base);
	line-height: var(--font-line-height-base);
}
`, "",{"version":3,"sources":["webpack://./routes/payments/discounts/create/form/content-heading/styles.css"],"names":[],"mappings":"AAAA;CACC,iCAAiC;CACjC,WAAW;CACX,6DAA6D;CAC7D,cAAc;AAKf;;AAHC;;AAND;EAOE,8CAA8C;AAEhD;CADC;;AAGD;CACC,+BAA+B;CAC/B,wCAAwC;CACxC,wCAAwC;AACzC;;AAEA;CACC,gCAAgC;CAChC,yCAAyC;AAC1C","sourcesContent":[".textWrapper {\n\tmax-width: var(--page-width-wide);\n\twidth: 100%;\n\tpadding: var(--spacing-8) var(--spacing-7) 0 var(--spacing-7);\n\tmargin: 0 auto;\n\n\t@media (--small-viewport) {\n\t\tpadding: var(--spacing-4) 0 var(--spacing-8) 0;\n\t}\n}\n\n.pageHeading {\n\tfont-size: var(--font-size-3xl);\n\tline-height: var(--font-line-height-2xl);\n\tfont-weight: var(--font-weight-semibold);\n}\n\n.description {\n\tfont-size: var(--font-size-base);\n\tline-height: var(--font-line-height-base);\n}\n"],"sourceRoot":""}]);
// Exports
export var textWrapper = `JDC70P8AJTcXiOr8pTFy`;
export var pageHeading = `lpnrNXIRM6KPa9v18Fbi`;
export var description = `wZNQUa3o7a8uDsVp9KF8`;
export default ___CSS_LOADER_EXPORT___;
