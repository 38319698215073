import * as models from 'pkg/api/models';

interface OrganizationUserProfileAttendanceProps {
	user: models.user.User;
}

export default function OrganizationUserProfileAttendance({
	user,
}: OrganizationUserProfileAttendanceProps): JSX.Element {
	return <p>Showing Attendance for {models.user.fullName(user)}</p>;
}
