import * as models from 'pkg/api/models';

interface OrganizationUserProfilePaymentsProps {
	user: models.user.User;
}

export default function OrganizationUserProfilePayments({
	user,
}: OrganizationUserProfilePaymentsProps): JSX.Element {
	return <p>Showing Payments for {models.user.fullName(user)}</p>;
}
