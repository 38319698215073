import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';
import * as routes from 'pkg/router/routes';
import { useCurrentOrganization } from 'pkg/identity';

import Products from 'routes/organization/user-profile/overview/payments/Products';
import Invoices from 'routes/organization/user-profile/overview/payments/Invoices';
import Subscriptions from 'routes/organization/user-profile/overview/payments/Subscriptions';

import { TabBar, Tab, TabView } from 'components/tab-bar';
import Section from 'components/section';

import * as Card from 'design/card';
import Button from 'design/button';

import * as css from './styles.css';

interface PaymentsProps {
	user: models.user.User;
}

export default function Payments({ user }: PaymentsProps): JSX.Element {
	const t = useT();
	const org = useCurrentOrganization();

	const tabs: Tab[] = [];

	const showInvoices: boolean = models.hasAllowedAction(
		user,
		models.Action.UserListOrders
	);

	const showSubscriptions: boolean = models.hasAllowedAction(
		user,
		models.Action.UserListSubscriptions
	);

	const showProducts: boolean = models.hasAllowedAction(
		user,
		models.Action.UserListProducts
	);

	if (showInvoices) {
		tabs.push({ id: 'invoices', label: t('Invoices') });
	}

	if (showSubscriptions) {
		tabs.push({ id: 'subscriptions', label: t('Subscriptions') });
	}

	if (showProducts) {
		tabs.push({ id: 'products', label: t('Products') });
	}

	if (tabs.length === 0) {
		return null;
	}

	return (
		<Section
			icon="paid"
			title={t('Payments')}
			action={
				<Button
					small
					inline
					icon="arrow_forward_ios"
					iconPosition="right"
					href={routes.Organization.User.Profile.Payments(org.id, user.id)}>
					{t('Show all')}
				</Button>
			}>
			<Card.Base $noBorder>
				<TabBar tabs={tabs} className={css.tabBar}>
					<Card.Divider />
					{showInvoices && (
						<TabView tabId="invoices">
							<Card.Body $noSpacing>
								<Invoices user={user} />
							</Card.Body>
						</TabView>
					)}
					{showSubscriptions && (
						<TabView tabId="subscriptions">
							<Card.Body $noSpacing>
								<Subscriptions user={user} />
							</Card.Body>
						</TabView>
					)}
					{showProducts && (
						<TabView tabId="products">
							<Card.Body $noSpacing>
								<Products user={user} />
							</Card.Body>
						</TabView>
					)}
				</TabBar>
			</Card.Base>
		</Section>
	);
}
