import * as styles from 'pkg/config/styles';

import * as models from 'pkg/api/models';

import Payments from 'routes/organization/user-profile/overview/payments';
import Relationships from 'routes/organization/user-profile/overview/relationships';
import Registrations from 'routes/organization/user-profile/overview/registrations';
import Memberships from 'routes/organization/user-profile/overview/memberships';
import Attendance from 'routes/organization/user-profile/overview/attendance';
import PhysicalStrain from 'routes/organization/user-profile/overview/physical-strain';

import Column from 'components/layout/column';

interface UserProfileOverviewProps {
	user: models.user.User;
}
export default function UserProfileOverview({
	user,
}: UserProfileOverviewProps): JSX.Element {
	return (
		<Column spacing={styles.spacing._7}>
			<Relationships user={user} />
			<Payments user={user} />
			<Registrations user={user} />
			<Memberships user={user} />
			<Attendance user={user} />
			<PhysicalStrain user={user} />
		</Column>
	);
}
