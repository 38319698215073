import { T, useT } from '@transifex/react';
import { useState } from 'react';
import { Browser } from '@capacitor/browser';

import spacing from 'pkg/config/spacing';

import { inAppRegistrationsEnabled } from 'pkg/flags';
import * as routes from 'pkg/router/routes';
import * as formsService from 'pkg/forms_service';
import { useCurrentAccount } from 'pkg/identity';
import { isApp } from 'pkg/platform';
import * as models from 'pkg/api/models';
import { cssClasses } from 'pkg/css/utils';

import Badge from 'components/Badge';
import Label from 'components/label';
import * as Card from 'components/Card';
import AssetImage from 'components/AssetImage';

import Row from 'components/layout/row';
import Column from 'components/layout/column';
import ImageAttachment from 'components/attachment/ImageAttachment';
import AvatarStack from 'components/avatar/avatar-stack';

import Button from 'design/button';

import * as css from './styles.css';

interface FormsGridItemProps {
	form: models.form.Form;
	group: models.group.Group;
}

export default function FormsGridItem({ form, group }: FormsGridItemProps) {
	const t = useT();
	const account = useCurrentAccount();

	const [isAuthing, setIsAuthing] = useState(false);
	const authenticate = formsService.useAuthenticator();

	const handleOpenRegistration = async () => {
		setIsAuthing(true);
		const url = await authenticate(account.id, form);

		if (isApp()) {
			Browser.open({ url: url });
		} else {
			window.location.href = url;
		}

		setIsAuthing(false);
	};

	const submissionsString = form.submissions
		?.map((fs) => fs.submittedForUser.firstName)
		.join(', ');

	let statusColor = 'green';

	if (form.status === models.form.Statuses.Closed) {
		statusColor = 'gray';
	}

	let coverImageContent = (
		<Badge badgeUrl={group.profileImageUrl} size="85px" />
	);

	if (form.attachment) {
		coverImageContent = <ImageAttachment attachment={form.attachment} />;
	}

	const availableSpots = form.maxSubmissions - form.submissionCount;

	let availabilityText = t(
		'Available seats: {availableSpots} of {maxSubmissions} ',
		{
			availableSpots,
			maxSubmissions: form.maxSubmissions,
		}
	);

	if (availableSpots === 0) {
		availabilityText = t('No seats left');
	}

	let fireThreshold = 0.1;

	if (form.maxSubmissions <= 10) {
		fireThreshold = 0.3;
	}

	const showFireSvg =
		availableSpots / form.maxSubmissions < fireThreshold &&
		availableSpots !== 0;

	return (
		<Card.Base className={css.cardBase}>
			<div className={cssClasses(css.image)}>{coverImageContent}</div>
			<Card.Body $flex>
				<Column className={css.bodyColumn} spacing={spacing._6}>
					<Column spacing={spacing._3}>
						<Row autoColumns="max-content" spacing={spacing._2}>
							<Label color={statusColor}>
								{models.form.getTranslatedStatus(form.status)}
							</Label>
							{!!form.maxSubmissions && (
								<Label color="orange">
									{showFireSvg && <AssetImage src="img/fire.svg" />}
									{availabilityText}
								</Label>
							)}
						</Row>
						<Column spacing={spacing._1}>
							<div className={css.title}>{form.title}</div>
							<div className={css.description}>{form.description}</div>
						</Column>
					</Column>
					<Column>
						{form.submissions && (
							<AvatarStack
								users={form.submissions.map((sb) => sb.submittedForUser)}
								renderRestWith={
									<span className={css.stackText}>
										<T
											_str="Registered by you: {submissions}"
											submissions={
												<div className={css.submissionNames}>
													{submissionsString}
												</div>
											}
										/>
									</span>
								}
								size={25}
							/>
						)}
						{inAppRegistrationsEnabled() ? (
							<Button
								label={t('Register now')}
								isLoading={isAuthing}
								secondary
								href={routes.Registration.Preview(form.guid)}
							/>
						) : (
							<Button
								label={t('Register now')}
								isLoading={isAuthing}
								secondary
								onClick={handleOpenRegistration}
							/>
						)}
					</Column>
				</Column>
			</Card.Body>
		</Card.Base>
	);
}
