import { Fragment, useState } from 'react';
import { t } from '@transifex/native';

import * as routes from 'pkg/router/routes';
import { formatPriceIntervalToText } from 'pkg/utils';
import DateTime from 'pkg/datetime';
import * as models from 'pkg/api/models';
import useTooltip from 'pkg/hooks/useTooltip';
import { useCurrentOrganization } from 'pkg/identity';
import { CollectionSelection } from 'pkg/api/use_collection';

import CancelSubscriptionModal from 'containers/payment_platform/subscriptions/CancelModal';

import Icon from 'components/icon';

import StatusLabel from 'components/payment_platform/status/label';
import * as Input from 'components/form/inputs';

import * as ContextMenu from 'design/context_menu';
import * as Table from 'design/table';

interface SubscriptionRowProps {
	subscription: models.subscription.Subscription;
	selection: CollectionSelection;
}

export default function SubscriptionRow({
	subscription,
	selection,
}: SubscriptionRowProps) {
	const [showModal, setShowModal] = useState(false);

	const org = useCurrentOrganization();
	const startAt = new DateTime(new Date(subscription.startAt * 1000));
	const created = new DateTime(new Date(subscription.createdAt * 1000));
	const currentPeriodEnd =
		subscription.currentPeriodEnd &&
		new DateTime(
			new Date(subscription.currentPeriodEnd * 1000)
		).toLocaleDateString({
			year: 'numeric',
			month: 'numeric',
			day: 'numeric',
		});

	const subscriptionRoute = routes.Subscription.Show(
		org.id,
		subscription.id,
		'overview'
	);

	const assignedContacts = [
		...new Map(
			subscription.rows
				.filter((row) => row?.user)
				.map((row) => row.user)
				.map((user) => [user.id, user])
		).values(),
	];

	const assignedContact =
		assignedContacts.length > 0 ? assignedContacts[0] : null;

	const canEdit = models.subscription.subscriptionCanBeEdited(subscription);

	const { onMouseEnter, tooltip } = useTooltip(
		assignedContacts.map((contact) => models.user.fullName(contact)).join(', ')
	);

	const handleCancelClick = () => setShowModal(true);

	const rowActions = (
		<Table.ActionCell>
			<ContextMenu.Menu
				toggleWith={
					<ContextMenu.TableTrigger>
						<Icon name="context-menu" />
					</ContextMenu.TableTrigger>
				}>
				<ContextMenu.LinkItem href={subscriptionRoute}>
					<ContextMenu.ItemIcon name="arrow_forward" />
					{t('View subscription')}
				</ContextMenu.LinkItem>
				<ContextMenu.LinkItem
					href={routes.Management.Contact.Show(
						org.id,
						subscription.customerUserId,
						'overview'
					)}>
					<ContextMenu.ItemIcon name="person" />
					{t('View contact')}
				</ContextMenu.LinkItem>
				<ContextMenu.LinkItem
					disabled={!canEdit}
					href={routes.Subscription.Edit(org.id, subscription.id)}>
					<ContextMenu.ItemIcon name="edit" />
					{t('Edit subscription')}
				</ContextMenu.LinkItem>
				<ContextMenu.Divider />

				<ContextMenu.Item
					disabled={!canEdit}
					caution
					onClick={handleCancelClick}>
					<ContextMenu.ItemIcon name="block" />
					{t('Cancel subscription')}
				</ContextMenu.Item>
			</ContextMenu.Menu>
		</Table.ActionCell>
	);

	let assignedContactsContent = null;

	if (assignedContacts.length === 0) {
		assignedContactsContent = <span>-</span>;
	} else if (assignedContacts.length === 1) {
		assignedContactsContent = (
			<span>{models.user.fullName(assignedContact)}</span>
		);
	} else {
		assignedContactsContent = (
			<Fragment>
				{tooltip}
				<span onMouseEnter={onMouseEnter}>
					{t('{amount} contacts', {
						amount: assignedContacts.length,
					})}
				</span>
			</Fragment>
		);
	}

	const products =
		models.subscription
			.findProductRows(subscription)
			.map((r) => r.productPrice.product) || [];

	let productText = '';

	if (products.length > 1) {
		productText = t('{amount} products', { amount: products.length });
	} else if (products.length === 1) {
		productText = products[0].name;
	}

	return (
		<Fragment>
			<Table.Row href={subscriptionRoute}>
				<Table.Cell>
					<Input.Control
						type="checkbox"
						standalone
						checked={selection.isSelected(subscription.id)}
						onChange={() => selection.selectSingle(subscription.id)}
					/>
				</Table.Cell>
				<Table.LinkCell>
					{models.user.fullName(subscription.customerUser)}
				</Table.LinkCell>
				<Table.LinkCell>
					{models.user.getBillingEmail(subscription.customerUser)}
				</Table.LinkCell>
				<Table.LinkCell>{assignedContactsContent}</Table.LinkCell>
				<Table.LinkCell>{productText}</Table.LinkCell>
				<Table.LinkCell>
					{models.subscription.getTypeString(subscription)}
				</Table.LinkCell>
				<Table.LinkCell>
					<StatusLabel
						status={models.subscription.getStatus(subscription)}
						jobStatus={subscription.jobStatus}
					/>
				</Table.LinkCell>
				<Table.LinkCell>
					{formatPriceIntervalToText(
						subscription.recurringInterval,
						subscription.recurringIntervalCount
					)}
				</Table.LinkCell>
				<Table.LinkCell>
					{models.subscription.getCollectionMethodString(subscription)}
				</Table.LinkCell>
				<Table.LinkCell>
					{startAt.toLocaleDateString({
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					})}
				</Table.LinkCell>
				<Table.LinkCell>{currentPeriodEnd}</Table.LinkCell>
				<Table.LinkCell>
					{created.toLocaleDateString({
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					})}
				</Table.LinkCell>
				{rowActions}
			</Table.Row>

			{showModal && (
				<CancelSubscriptionModal
					hideModal={() => setShowModal(false)}
					subscription={subscription}
				/>
			)}
		</Fragment>
	);
}
