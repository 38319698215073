// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nFPhKi_mlK6TK1Q2M5iI {
	padding: var(--spacing-4) var(--spacing-5);
	padding-bottom: 0;
}

.Uq9znDPjzyzkAKwMtuyQ {
	padding-top: var(--spacing-8) !important;
	padding-bottom: var(--spacing-8) !important;
}

.xyR8K7s3bXqLdOUE2bEB {
	color: var(--palette-gray-500);
}

.Avu2PDC_j4gZYfOYkQR8 {
	display: grid;
	grid-template-columns: auto 1fr auto;
}
`, "",{"version":3,"sources":["webpack://./routes/organization/user-profile/overview/payments/styles.css"],"names":[],"mappings":"AAAA;CACC,0CAA0C;CAC1C,iBAAiB;AAClB;;AAEA;CACC,wCAA0C;CAA1C,2CAA0C;AAC3C;;AAEA;CACC,8BAA8B;AAC/B;;AAEA;CACC,aAAa;CACb,oCAAoC;AACrC","sourcesContent":[".tabBar {\n\tpadding: var(--spacing-4) var(--spacing-5);\n\tpadding-bottom: 0;\n}\n\n.emptyState {\n\tpadding-block: var(--spacing-8) !important;\n}\n\n.emptyStateIcon {\n\tcolor: var(--palette-gray-500);\n}\n\n.contextItemWithExtraIcon {\n\tdisplay: grid;\n\tgrid-template-columns: auto 1fr auto;\n}\n"],"sourceRoot":""}]);
// Exports
export var tabBar = `nFPhKi_mlK6TK1Q2M5iI`;
export var emptyState = `Uq9znDPjzyzkAKwMtuyQ`;
export var emptyStateIcon = `xyR8K7s3bXqLdOUE2bEB`;
export var contextItemWithExtraIcon = `Avu2PDC_j4gZYfOYkQR8`;
export default ___CSS_LOADER_EXPORT___;
