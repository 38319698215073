import { t } from '@transifex/native';

import { Filter, FilterSetter } from 'pkg/filters/use_filters';

import * as ActionBar from 'components/layout/ActionBar';

import Button from 'design/button';

interface ActionBarProps {
	onGoToCreate?: () => void;
	searchFilter?: Omit<Filter, 'id'>;
	filterSetter?: FilterSetter;
}

const SelectProductActionBar: React.FC<
	React.PropsWithChildren<ActionBarProps>
> = ({ onGoToCreate, searchFilter, filterSetter }) => {
	return (
		<ActionBar.FilterBar>
			<ActionBar.PrimaryAction>
				<ActionBar.Search
					placeholder={t(`Search products`)}
					filter={searchFilter}
					filterSetter={filterSetter}
					inModal
				/>
			</ActionBar.PrimaryAction>
			<Button secondary onClick={onGoToCreate} icon="add">
				{t('New product')}
			</Button>
		</ActionBar.FilterBar>
	);
};

export default SelectProductActionBar;
