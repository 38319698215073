import { t } from '@transifex/native';
import { Fragment } from 'react';

import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { SubscriptionStatus } from 'pkg/api/models/subscription';
import { FilterOperator } from 'pkg/filters';
import { useCurrentUser } from 'pkg/identity';

import SubscriptionItem from 'routes/user/billing/subscription_list/SubscriptionItem';

import MissingEntities from 'components/missing-entities';
import CardAnatomy, * as Card from 'components/Card';
import Icon from 'components/icon';

import Column from 'components/layout/column';
import { Spinner } from 'components/loaders/spinner';

function sortSubscriptions(
	a: models.subscription.Subscription,
	b: models.subscription.Subscription
) {
	const statusOrder = [
		SubscriptionStatus.Pending,
		SubscriptionStatus.Active,
		SubscriptionStatus.Uncollectible,
		SubscriptionStatus.Completed,
		SubscriptionStatus.Canceled,
		SubscriptionStatus.Trialing,
		SubscriptionStatus.Scheduled,
		SubscriptionStatus.Unknown,
	];

	return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
}

const Subscriptions = () => {
	const currentUser = useCurrentUser();

	const subParams = {
		group_id: currentUser.organizationId.toString(),
		filters: JSON.stringify([
			{
				property: 'status',
				operator: FilterOperator.Includes,
				values: [
					SubscriptionStatus.Active,
					SubscriptionStatus.Canceled,
					SubscriptionStatus.Trialing,
					SubscriptionStatus.Completed,
					SubscriptionStatus.Uncollectible,
					SubscriptionStatus.Unknown,
				],
			},
		]),
	};

	const { records: subscriptions, isLoading } =
		useCollection<models.subscription.Subscription>(
			endpoints.Subscriptions.ListSubscriptions(),
			{
				queryParams: new URLSearchParams(subParams),
			}
		);

	if (isLoading) {
		return <Spinner />;
	}

	const sortedSubscriptions = subscriptions.sort(sortSubscriptions);

	const content = !subscriptions.length ? (
		<CardAnatomy $noBorder>
			<Card.Body>
				<MissingEntities centered>
					<Icon name="sync" />
					<p>{t('No active subscriptions')}</p>
				</MissingEntities>
			</Card.Body>
		</CardAnatomy>
	) : (
		<Fragment>
			{sortedSubscriptions.map((subscription) => (
				<SubscriptionItem
					key={subscription.id}
					subscription={subscription}
					groupId={currentUser.organizationId}
					userId={currentUser.id}
				/>
			))}
		</Fragment>
	);

	return <Column>{content}</Column>;
};

export default Subscriptions;
