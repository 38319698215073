import { useState, useContext, useRef, Fragment, useEffect } from 'react';
import { useT } from '@transifex/react';

import { PageWidths } from 'pkg/config/sizes';

import { UpdateSubscriptionPayload } from 'pkg/actions/subscriptions';
import { PaymentPreviewPayload, PaymentRow } from 'pkg/actions/payment_preview';

import * as routes from 'pkg/router/routes';
import { replaceState } from 'pkg/router/state';
import * as actions from 'pkg/actions';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import useMixedState from 'pkg/hooks/useMixedState';
import { SubscriptionTypes } from 'pkg/api/models/subscription';
import { cssClasses } from 'pkg/css/utils';

import {
	AddOrderContext,
	AssignedProductProps,
	emptyState,
	OrderMixedState,
} from 'routes/payments/orders/create';

import EditForm from 'containers/payment_platform/subscriptions/EditForm';

import {
	LargeScreen,
	SmallScreen,
	useSmallScreen,
} from 'components/MediaQuery';

import * as ActionBar from 'components/layout/ActionBar';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import { Spinner } from 'components/loaders/spinner';
import Form, { asNumber, FormPayload, submitForm } from 'components/form/Form';
import * as pageWrapperStyling from 'components/payment_platform/form-page/styles.css';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import SmallScreenHeader from 'components/navigation/header/small_screen';
import { useAppState } from 'components/application/state';
import PaymentPreview from 'components/payment_platform/preview';

import Button from 'design/button';

interface EditSubscriptionProps {
	organizationId: number;
	id: string;
}

function EditSubscriptionWrapper(props: EditSubscriptionProps) {
	const [formState, setFormState] = useMixedState<OrderMixedState>(emptyState);

	const newAssignedProduct = (assignedProduct: AssignedProductProps) => {
		let newRows = formState.rows;
		const rowIndex = formState.rows.findIndex(
			(row) =>
				row.productPrice.id === assignedProduct.productPrice.id &&
				row.taxRateId === assignedProduct.taxRateId
		);

		if (rowIndex !== -1) {
			newRows[rowIndex].quantity += assignedProduct.quantity;
		} else {
			newRows = [...newRows, assignedProduct];
		}

		setFormState({
			rows: newRows,
		});
	};

	return (
		<AddOrderContext.Provider
			value={{
				formState,
				setFormState,
				isSubscription: true,
				newAssignedProduct,
			}}>
			<EditSubscription {...props} />
		</AddOrderContext.Provider>
	);
}

function EditSubscription({ organizationId, id }: EditSubscriptionProps) {
	const t = useT();
	const formRef = useRef(null);

	const subscriptionId = Number.parseInt(id, 10);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const OrderContext = useContext(AddOrderContext);
	const [preview, setPreview] =
		useState<models.paymentPreview.PaymentPreview>(null);

	const { keyboardOpen } = useAppState();

	let assignedProductsProps: AssignedProductProps[] = [];

	const { record: subscription, isLoading: isLoadingSubscription } =
		useEndpoint<models.subscription.Subscription>(
			endpoints.Subscriptions.Show(subscriptionId),
			{},
			(subscription) => {
				if (subscription.rows?.length) {
					assignedProductsProps = models.subscription
						.findProductRows(subscription)
						.map((subscriptionRow) => {
							return {
								product: subscriptionRow.productPrice.product,
								productPrice: subscriptionRow.productPrice,
								quantity: subscriptionRow.quantity,
								taxRateId: Number.parseInt(
									subscriptionRow.taxRateId as unknown as string,
									10
								),
								validTo: subscriptionRow.validTo,
								taxRate: subscriptionRow.taxRate,
							};
						});
				}

				const discount = models.subscription.findDiscount(subscription);

				const updateObj: {
					rows: AssignedProductProps[];
					installmentCount?: number;
					discount?: models.discount.Discount;
				} = {
					rows: assignedProductsProps,
				};

				if (subscription.type === SubscriptionTypes.Installment) {
					updateObj.installmentCount = subscription.installmentCount;
				}

				if (discount) {
					updateObj.discount = discount;
				}

				OrderContext.setFormState(updateObj);
			}
		);

	useEffect(() => {
		const fetchData = async () => {
			const previewPayload: PaymentPreviewPayload = {
				groupId: organizationId,
				rows: OrderContext.formState.rows.map((r) => {
					const row: PaymentRow = {
						productPriceId: r.productPrice.id,
						quantity: r.quantity,
					};

					if (r.taxRateId) {
						row.taxRateId = r.taxRateId;
					}
					return row;
				}),
			};

			if (OrderContext.formState.discount) {
				previewPayload.discountId = OrderContext.formState.discount?.id;
			}

			if (OrderContext.formState.installmentCount) {
				previewPayload.installmentCount =
					OrderContext.formState.installmentCount -
					subscription.installmentsPaid;
			}

			const [req, resp] =
				await actions.paymentPreview.getPreview(previewPayload);

			if (req) {
				setPreview(resp);
			}
		};

		fetchData();
	}, [JSON.stringify(OrderContext.formState)]);

	const filteredRows = OrderContext.formState.rows.filter(
		(item: AssignedProductProps) => item.productPrice.recurring === true
	);
	const oneTimeRows = OrderContext.formState.rows.some(
		(item: AssignedProductProps) => item.productPrice.recurring !== true
	);

	const noConflictingPriceCycles =
		filteredRows.length > 0
			? filteredRows.every(
					(obj: AssignedProductProps) =>
						obj.productPrice.recurringInterval ===
							filteredRows[0].productPrice.recurringInterval &&
						obj.productPrice.recurringIntervalCount ===
							filteredRows[0].productPrice.recurringIntervalCount
				)
			: true;

	const disableUpdate =
		!noConflictingPriceCycles ||
		oneTimeRows ||
		OrderContext.formState.rows.length === 0;

	const content = <EditForm subscription={subscription} />;

	const handleUpdate = async (data: FormPayload) => {
		setIsSaving(true);
		const daysUntilDue = asNumber(data?.daysUntilDue);
		const payload: UpdateSubscriptionPayload = {};

		if (
			JSON.stringify(OrderContext.formState.rows) !==
			JSON.stringify(assignedProductsProps)
		) {
			payload.rows = OrderContext.formState.rows.map(
				(row: AssignedProductProps) => {
					const singleRow: {
						priceId: number;
						quantity: number;
						taxRateId: number;
						validTo?: number;
					} = {
						priceId: row.productPrice.id,
						quantity: row.quantity,
						taxRateId: row.taxRateId,
					};
					if (row.validTo) {
						singleRow.validTo = row.validTo;
					}
					return singleRow;
				}
			);
		}

		if (daysUntilDue) {
			payload.daysUntilDue = daysUntilDue;
		}

		if (
			OrderContext.formState.collectionMethod !== undefined &&
			OrderContext.formState.collectionMethod !== subscription.collectionMethod
		) {
			payload.collectionMethod = OrderContext.formState.collectionMethod;
		}

		if (OrderContext.formState.discount) {
			payload.discountId = OrderContext.formState.discount.id;
		}

		// Check for changes in the states installmentCount vs subscription installmentCount
		if (
			OrderContext.formState.installmentCount !==
				subscription.installmentCount &&
			subscription.type === SubscriptionTypes.Installment
		) {
			payload.installmentCount = OrderContext.formState.installmentCount;
		}

		const req: boolean = await actions.subscriptions.update(
			subscriptionId,
			payload
		);
		if (req) {
			replaceState(
				routes.Subscription.Show(organizationId, subscriptionId, 'overview')
			);
		}
		setIsSaving(false);
	};

	const handleUpdateClick = async () => submitForm(formRef);

	const isSmallScreen = useSmallScreen();

	const totalAmount = keyboardOpen ? null : (
		<PaymentPreview
			preview={preview}
			productsWithTaxesExists={OrderContext.formState.rows.some(
				(r) => r.taxRate
			)}
			editSubscriptionView={true}
			extraPreviewValues={{
				paidInstallments: subscription.installmentPaidAmount,
			}}
		/>
	);

	const pageContent = (
		<Form formRef={formRef} onSubmit={handleUpdate}>
			<ActionBar.SaveBar maxWidth={PageWidths.STANDARD}>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					href={routes.Subscription.Show(
						organizationId,
						Number.parseInt(id, 10),
						'overview'
					)}>
					{t('Cancel')}
				</Button>
				<Button
					block={isSmallScreen}
					large={isSmallScreen}
					primary
					onClick={handleUpdateClick}
					disabled={isSaving || disableUpdate}>
					{t('Save')}
				</Button>
			</ActionBar.SaveBar>
			<LargeScreen>
				<LargeScreenContent.Inner maxWidth={PageWidths.STANDARD}>
					{content}
				</LargeScreenContent.Inner>
			</LargeScreen>
			<SmallScreen>
				<LargeScreenContent.Inner>{content}</LargeScreenContent.Inner>
			</SmallScreen>
		</Form>
	);

	if (isLoadingSubscription) {
		return <Spinner />;
	}

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={t('Edit subscription')} icon="sync" />

				<div className={pageWrapperStyling.wrapper}>
					<div className={pageWrapperStyling.contentWrapper}>{pageContent}</div>
					{totalAmount}
				</div>
			</LargeScreen>

			<SmallScreen>
				<SmallScreenHeader title={t('Edit subscription')} />
				<div
					className={cssClasses(
						pageWrapperStyling.wrapper,
						keyboardOpen ? pageWrapperStyling.fullHeight : null
					)}>
					<div className={pageWrapperStyling.contentWrapper}>{pageContent}</div>
					{totalAmount}
				</div>
			</SmallScreen>
		</Fragment>
	);
}

export default EditSubscriptionWrapper;
