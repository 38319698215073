import { t } from '@transifex/native';

import { Features } from 'pkg/models/group';

import * as actions from 'pkg/actions';
import DateTime from 'pkg/datetime';
import store from 'pkg/store/createStore';
import {
	useCurrentGroup,
	useCurrentMembership,
	useCurrentOrganization,
} from 'pkg/identity';
import * as models from 'pkg/api/models';

import Actions from 'routes/organization/contacts/single/sidebar/top/Actions';

import Avatar from 'components/avatar';

import * as SideBarLayout from 'components/layout/SideBarPage';
import Column from 'components/layout/column';
import ImageEditor from 'components/image/Editor';

import Button from 'design/button';
import * as ContextMenu from 'design/context_menu';

import * as css from './styles.css';

const SideBarTop = ({
	contact,
	restoreUser,
	openModal,
	refreshMemberships,
	refreshUser,
}: {
	groupId: number;
	contact: models.user.User;
	restoreUser: () => void;
	openModal: (modalName: string) => void;
	refreshMemberships: () => void;
	refreshUser: () => void;
}): JSX.Element => {
	const membership = useCurrentMembership();
	const organization = useCurrentOrganization();
	const group = useCurrentGroup();

	const createdAt = new DateTime(new Date(contact.createdAt * 1000));

	const handleAvatarChange = (value: string) => {
		actions.users.update(contact.id, { profileImage: value })(store.dispatch);
	};

	const canChat =
		models.group.hasFeature(organization, Features.Chat) &&
		contact.accountId &&
		contact.accountId !== membership.user.accountId;

	return (
		<Column>
			{models.group.isOrganization(group) &&
			models.membership.isAdmin(membership) ? (
				<ContextMenu.Menu
					toggleWith={
						<SideBarLayout.AvatarWrapper>
							<Avatar user={contact} size={100} isEditable />
						</SideBarLayout.AvatarWrapper>
					}>
					<ContextMenu.MenuContext.Consumer>
						{({ close }) => (
							<ContextMenu.Pane>
								<ImageEditor
									image={contact.profileImageUrl}
									onSave={handleAvatarChange}
									afterSave={close}
								/>
							</ContextMenu.Pane>
						)}
					</ContextMenu.MenuContext.Consumer>
				</ContextMenu.Menu>
			) : (
				<SideBarLayout.AvatarWrapper>
					<Avatar user={contact} size={100} />
				</SideBarLayout.AvatarWrapper>
			)}
			<div>
				<p className={css.contactInfoName}>{models.user.fullName(contact)}</p>
				{contact.email && (
					<a href={`mailto:${contact.email}`}>{contact.email}</a>
				)}
				<div>
					<p className={css.createdDate}>
						{t('Created: {createdAt}', {
							createdAt: createdAt.toLocaleDateString(),
						})}
					</p>
				</div>
			</div>
			{!!contact.deletedAt ? (
				<Button primary onClick={restoreUser}>
					{t('Restore user')}
				</Button>
			) : (
				<Actions
					group={group}
					contact={contact}
					canChat={canChat}
					openModal={openModal}
					refreshMemberships={refreshMemberships}
					refreshUser={refreshUser}
				/>
			)}
		</Column>
	);
};

export default SideBarTop;
