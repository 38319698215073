import { t } from '@transifex/native';
import { useEffect } from 'react';

import * as routes from 'pkg/router/routes';
import { useCurrentRoute } from 'pkg/router/hooks';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useEndpoint } from 'pkg/api/use_endpoint';
import * as models from 'pkg/api/models';
import { useCurrentGroup } from 'pkg/identity';

import { ShowFormResponse } from 'routes/forms/registration/preview';

import StatusScreen from 'components/status-screen';

import { Spinner } from 'components/loaders/spinner';

import Button from 'design/button';

interface SuccessProps {
	setPageTitle: (title: string) => void;
}

export default function Success({ setPageTitle }: SuccessProps) {
	const route = useCurrentRoute();
	const guid = route.guid;
	const formSubmissionId = route?.formSubmissionId;
	const group = useCurrentGroup();

	const { record: response, isLoading } = useEndpoint<ShowFormResponse>(
		endpoints.Forms.PublicShow(guid)
	);

	const { record: submission, isLoading: isSubmissionLoading } =
		useEndpoint<models.formSubmission.FormSubmission>(
			endpoints.FormSubmissions.Show(formSubmissionId)
		);

	useEffect(() => {
		if (!isLoading && response) {
			setPageTitle(response.form.title);
		}
	}, [isLoading]);

	if (isLoading || isSubmissionLoading) {
		return <Spinner />;
	}

	let descriptionText;

	if (response.form.productPrices.length >= 1) {
		descriptionText = t(
			'Thank you for registering {user} to {formTitle} - a confirmation has been sent to {email}. Please note that any future invoices from {group} may be sent to this email.',
			{
				user: models.user.fullName(submission.submittedForUser),
				formTitle: response.form.title,
				email: submission.submittedByUser.email,
				group: group.name,
			}
		);
	} else {
		descriptionText = t('Thank you for registering {user} to {formTitle}', {
			user: models.user.fullName(submission.submittedForUser),
			formTitle: response.form.title,
		});
	}

	return (
		<StatusScreen
			fullscreen
			status="success"
			title={t('Success!')}
			description={descriptionText}>
			<Button
				primary
				large
				href={routes.Registration.Preview(guid)}
				data-testid="registration.finish">
				{t('Finish')}
			</Button>
		</StatusScreen>
	);
}
