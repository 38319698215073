import React, {
	Fragment,
	useState,
	cloneElement,
	useMemo,
	ReactElement,
	useEffect,
	ReactNode,
} from 'react';
import { useT } from '@transifex/react';

import * as styles from 'pkg/config/styles';

import { ApiError } from 'pkg/errors/errors';
import * as routes from 'pkg/router/routes';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCurrentRoute, usePreviousRoutePath } from 'pkg/router/hooks';
import { useEndpoint } from 'pkg/api/use_endpoint';

import UserHeader from 'routes/organization/user-profile/user-header';
import { UserActions } from 'routes/organization/user-profile/user-actions';
import UserDetails from 'routes/organization/user-profile/user-details';
import UserTimestamps from 'routes/organization/user-profile/UserTimestamps';
import BrandedHeaderLayout from 'routes/organization/user-profile/layout/BrandedHeaderLayout';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import MaterialSymbol from 'components/material-symbols';

import { MaterialSymbolVariant } from 'components/material-symbols/symbols';
import LargeScreenHeader from 'components/navigation/header/large_screen';
import * as LargeScreenContent from 'components/layout/LargeScreenContent';
import * as LargeLayout from 'components/navigation/header/large_screen/Styles';
import * as SideBarLayout from 'components/layout/SideBarPage';
import { LayoutPortal, LayoutPortalLocation } from 'components/layout/portal';
import { PageProps, SubNavItemProps } from 'components/layout/PageTemplates';
import Column from 'components/layout/column';
import Panel from 'components/sidebar/panel';

import Button from 'design/button';

import * as css from './styles.css';

interface UserProfileLayoutCrashProps {
	error: ApiError;
}

function UserProfileLayoutCrash({
	error,
}: UserProfileLayoutCrashProps): JSX.Element {
	const t = useT();
	const backUrl = usePreviousRoutePath();

	let symbolVariant: MaterialSymbolVariant = 'error';

	let { title, body } = error;

	if (error.code === 404) {
		symbolVariant = 'account_circle_off';
		title = t('User not found');
		body = t('The requested user could not be found.');
	}

	if (error.code === 403) {
		symbolVariant = 'supervised_user_circle_off';
		title = t('Forbidden');
		body = t('You are not authorized to view this user.');
	}

	let backButton: ReactNode = (
		<Button primary href={routes.Home()}>
			{t('Back to start')}
		</Button>
	);

	if (backUrl !== '') {
		backButton = (
			<Button primary href={backUrl}>
				{t('Go back')}
			</Button>
		);
	}

	return (
		<Column justify="center" className={css.wrapper}>
			<MaterialSymbol variant={symbolVariant} actualSize scale={3} />
			<h3 className={css.title}>{title}</h3>
			<p className={css.body}>{body}</p>
			{backButton}
		</Column>
	);
}

export function UserProfileLayout({
	title,
	icon,
	subNavItems = [],
	children,
}: PageProps): JSX.Element {
	const [pageTitle, setPageTitle] = useState<string>(title);
	const [pageIcon, setPageIcon] = useState<MaterialSymbolVariant>(icon);
	const [pageSubNavItems, setPageSubNavItems] =
		useState<SubNavItemProps[]>(subNavItems);

	const { userId } = useCurrentRoute();

	const {
		record: user,
		hasError,
		response,
	} = useEndpoint<models.user.User>(
		endpoints.Users.Show(userId),
		{
			queryParams: new URLSearchParams({
				include_user_products: 'true',
				include_billing_contact: 'true',
			}),
		},
		(record) => {
			if (record?.id) {
				setPageTitle(models.user.fullName(record));
			}
		}
	);

	children = useMemo(
		() =>
			React.Children.map(children, (c: ReactElement) =>
				cloneElement(c, {
					user,
					setPageTitle,
					setPageIcon,
					setPageSubNavItems,
				})
			),
		[children, user]
	);

	useEffect(() => {
		if (hasError) {
			setPageSubNavItems([]);
		}
	}, [hasError]);

	const errorFallback = (
		<UserProfileLayoutCrash error={new ApiError(response?.status)} />
	);

	return (
		<Fragment>
			<LargeScreen>
				<LargeScreenHeader title={pageTitle} icon={pageIcon}>
					{pageSubNavItems.map((item, n) => (
						<LargeLayout.SubNavItem key={n} {...item}>
							{item.title}
						</LargeLayout.SubNavItem>
					))}
				</LargeScreenHeader>
				<LargeScreenContent.Wrapper>
					<SideBarLayout.Layout>
						<SideBarLayout.SideBar data-hide-if-empty>
							<LayoutPortalLocation portalId="user-profile-header" />
							<LayoutPortalLocation portalId="user-profile-sidebar" />
						</SideBarLayout.SideBar>
						<SideBarLayout.Content>
							<LargeScreenContent.Inner maxWidth={styles.size.PageWidths.WIDE}>
								{hasError ? errorFallback : children}
							</LargeScreenContent.Inner>
						</SideBarLayout.Content>
					</SideBarLayout.Layout>
				</LargeScreenContent.Wrapper>
			</LargeScreen>
			<SmallScreen>
				<BrandedHeaderLayout pageSubNavItems={pageSubNavItems}>
					<LargeScreenContent.Inner noPadding>
						<div className={css.smallScreenSideBar}>
							<LayoutPortalLocation portalId="user-profile-sidebar" />
						</div>
						<div className={css.smallScreenInnerLayout}>
							{hasError ? errorFallback : children}
						</div>
					</LargeScreenContent.Inner>
				</BrandedHeaderLayout>
			</SmallScreen>
			<LayoutPortal portalId="user-profile-header">
				<UserHeader user={user} />
			</LayoutPortal>

			<LayoutPortal portalId="user-profile-sidebar">
				<UserActions user={user} />
				<UserDetails user={user} />

				<LargeScreen>
					<Panel footer>
						<UserTimestamps user={user} />
					</Panel>
				</LargeScreen>
			</LayoutPortal>
		</Fragment>
	);
}
