import { useEffect } from 'react';
import { t } from '@transifex/native';
import { T } from '@transifex/react';

import * as routes from 'pkg/router/routes';
import { useEndpoint } from 'pkg/api/use_endpoint';
import { useCollection } from 'pkg/api/use_collection';
import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import { useCurrentOrganization } from 'pkg/identity';

import { LargeScreen, SmallScreen } from 'components/MediaQuery';
import Badge from 'components/Badge';
import Label from 'components/label';
import { FormattedContent } from 'components/formatted-content';

import { Spinner } from 'components/loaders/spinner';
import AvatarStack from 'components/avatar/avatar-stack';

import Button from 'design/button';

import * as css from './styles.css';

interface Props {
	guid: string;
	setPageTitle: React.Dispatch<React.SetStateAction<string>>;
}

export interface ShowFormResponse {
	form: models.form.Form;
	paymentProviderSettings: models.providerSettings.ProviderSettings;
}

const statusLabels = {
	[models.form.Statuses.Draft]: t('Draft registration'),
	[models.form.Statuses.Open]: t('Open for registrations'),
	[models.form.Statuses.Closed]: t('Closed for registrations'),
};

const getStatusColor = (status: models.form.Statuses) => {
	switch (status) {
		case models.form.Statuses.Open:
			return 'green';
		case models.form.Statuses.Closed:
			return 'red';
		case models.form.Statuses.Draft:
			return 'gray';
	}
};

const PreviewForm = ({ guid, setPageTitle }: Props) => {
	const { record: response, isLoading: isFormLoading } =
		useEndpoint<ShowFormResponse>(endpoints.Forms.PublicShow(guid));

	const { records: submissions } =
		useCollection<models.formSubmission.FormSubmission>(
			endpoints.Forms.UserSubmissions(guid)
		);

	const submissionsString = submissions
		?.map((fs) => fs.submittedForUser.firstName)
		.join(', ');

	useEffect(() => {
		if (response?.form?.title) {
			setPageTitle(response.form.title);
		}
	}, [isFormLoading]);
	const org = useCurrentOrganization();

	if (isFormLoading) {
		return <Spinner />;
	}

	const hasBackgroundImage =
		response.form.attachment && response.form.attachment.url;

	const statusColor = getStatusColor(response.form.status);
	const isDisabled = response.form.status === models.form.Statuses.Closed;

	return (
		<div className={css.wrapper}>
			<div className={css.cover}>
				{hasBackgroundImage && (
					<div
						className={css.coverBackground}
						style={{
							backgroundImage: `url(${response.form.attachment.url})`,
						}}></div>
				)}
				<div className={css.coverContentWrapper}>
					<div className={css.coverContent}>
						{!hasBackgroundImage && (
							<div className={css.badgeWrapper}>
								<Badge badgeUrl={org.profileImageUrl} />
							</div>
						)}
					</div>
				</div>
			</div>

			<div className={css.contentWrapper}>
				<div className={css.content}>
					<div className={css.info}>
						<LargeScreen>
							<div className={css.title}>{response.form.title}</div>
						</LargeScreen>
						<div className={css.sectionHeading}>{t('Description')}</div>
						<div className={css.registrationDescription}>
							{response.form.description ? (
								<FormattedContent raw={response.form.description} />
							) : (
								<span>{t('No description')}</span>
							)}
						</div>
					</div>

					<div className={css.sidebar}>
						<Label color={statusColor}>
							{statusLabels[response.form.status]}
						</Label>
						<SmallScreen>
							<div className={css.title}>{response.form.title}</div>
						</SmallScreen>
						<div className={css.buttonWrapper}>
							<Button
								testid="registration.register"
								block
								large
								primary
								href={routes.Registration.Form(response.form.guid)}
								label={t('Register')}
								disabled={isDisabled}
							/>
						</div>
						{submissions && (
							<div>
								<AvatarStack
									users={submissions.map((sb) => sb.submittedForUser)}
									renderRestWith={
										<span className={css.stackText}>
											<T
												_str="Registered by you: {submissions}"
												submissions={
													<div className={css.submissionNames}>
														{submissionsString}
													</div>
												}
											/>
										</span>
									}
									size={25}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default PreviewForm;
