import { createContext, MutableRefObject, useContext } from 'react';

import * as language from 'pkg/i18n/language';

import { getDefaultDrawerState } from 'components/application/drawer';

export interface ApplicationState {
	scrollElement: MutableRefObject<any>;
	keyboardOpen: boolean;
	language: string;
	collapsedDrawer: boolean;
	setLanguage?: (lang: string) => void;
	setCollapsedDrawer?: (collapsed: boolean) => void;
	setScrollElement?: (scrollItem: MutableRefObject<any>) => void;
}

export const initialApplicationState: ApplicationState = {
	scrollElement: null,
	keyboardOpen: false,
	collapsedDrawer: getDefaultDrawerState(),
	language: language.get(),
};

export const ApplicationStateContext = createContext<ApplicationState>(
	initialApplicationState
);

export function useAppState(): ApplicationState {
	const ctx = useContext(ApplicationStateContext);
	return ctx;
}
