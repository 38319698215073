import { Dispatch, Fragment, SetStateAction, useState } from 'react';
import { t } from '@transifex/native';

import * as endpoints from 'pkg/api/endpoints/auto';
import * as models from 'pkg/api/models';
import * as actions from 'pkg/actions';
import { CollectionSelection, useCollection } from 'pkg/api/use_collection';
import * as routes from 'pkg/router/routes';

import AddToSingleGroupModal from 'containers/payment_platform/contacts/AddToSingleGroupModal';

import Label from 'components/label';
import Icon from 'components/icon';
import AssetImage from 'components/AssetImage';
import MembershipBulkActions from 'components/membership-bulk-actions';
import Avatar from 'components/avatar';

import Row from 'components/layout/row';
import * as ActionBar from 'components/layout/ActionBar';
import { AddUserModal } from 'components/group/add-users-modal';
import * as Input from 'components/form/inputs';
import { RemoveUserItem, useRemoveUserDialog } from 'components/user/remove';

import * as Table from 'design/table';
import * as ContextMenu from 'design/context_menu';
import Button from 'design/button';

import * as css from './styles.css';

interface MembershipRowProps {
	setModal: Dispatch<
		SetStateAction<{
			show: boolean;
			name: string;
			userId?: number;
		}>
	>;
	selection: CollectionSelection;
	membership: models.membership.Membership;
	organizationId: number;
	groupId: number;
	refresh: () => void;
}

function MembershipRow({
	membership,
	setModal,
	selection,
	organizationId,
	groupId,
	refresh,
}: MembershipRowProps) {
	const { user, userId } = membership;

	const editable_capabilities = [
		models.membership.Capabilities.Forms,
		models.membership.Capabilities.PaymentAdmin,
		models.membership.Capabilities.Scheduler,
	];

	const editable_roles = [
		models.membership.MembershipRole.Admin,
		models.membership.MembershipRole.Staff,
		models.membership.MembershipRole.User,
	];

	const [capabilities, setCapabilities] = useState<
		models.membership.Capabilities[]
	>(
		membership.capabilities.filter((cap) => editable_capabilities.includes(cap))
	);
	const [membershipRole, setMembershipRole] = useState<number>(
		membership.role as number
	);

	const getCapabilityLabelColor = (
		capability: models.membership.Capabilities
	) => {
		if (capability === models.membership.Capabilities.PaymentAdmin) {
			return 'green';
		}

		if (capability === models.membership.Capabilities.Forms) {
			return 'blue';
		}

		if (capability === models.membership.Capabilities.Scheduler) {
			return 'orange';
		}

		return 'blue';
	};

	const handleAddUserToGroup = (userId: number): void =>
		setModal({ show: true, name: 'group', userId });

	const handleAddToGroup = () => handleAddUserToGroup(userId);

	const handleRemoveUsers = async (
		removeUserRecursively: boolean,
		removeFromFutureEvents: boolean,
		userId: number
	) => {
		const req = await actions.memberships.removeUserFromGroup(groupId, userId, {
			removeUserRecursively,
			removeFromFutureEvents,
		});

		if (req) {
			refresh();
		}
	};

	const removeUserDialog = useRemoveUserDialog({
		handleRemoveUsers,
		userId: membership.userId,
	});

	const handleChangeRole = async (role: models.membership.MembershipRole) => {
		const [req] = await actions.groups.updateGroupMember({
			groupId,
			userId,
			payload: { role },
		});

		if (req) {
			setMembershipRole(role);
		}
	};

	const handleChangeCapability = async (
		capability: models.membership.Capabilities
	) => {
		const newCapabilities = capabilities.includes(capability)
			? capabilities.filter((cap) => cap != capability)
			: [...capabilities, capability];

		const payload: { capabilities: models.membership.Capabilities[] } = {
			capabilities: newCapabilities,
		};

		const res = await actions.groups.updateUserCapabilities({
			groupId,
			userId,
			payload,
		});

		if (res) {
			setCapabilities(newCapabilities);
		}
	};

	return (
		<Table.Row key={user.id}>
			<Table.Cell>
				<Input.Control
					onChange={() => selection.selectSingle(membership.id)}
					checked={selection.isSelected(membership.id)}
					type="checkbox"
					standalone
				/>
			</Table.Cell>
			<Table.LinkCell
				href={routes.Settings.User.Show(organizationId, groupId, userId)}>
				<div className={css.name}>
					<Avatar user={user} size={25} />
					<div className={css.nameInner}>
						<span>{models.user.fullName(user)}</span>
					</div>
				</div>
			</Table.LinkCell>
			<Table.LinkCell>
				{user.deletedAt || user.accountId ? (
					user.deletedAt ? (
						<Icon name="archive" />
					) : (
						<Icon
							name="key"
							size={1.5}
							tooltip={t('This user has a 360Player account')}
						/>
					)
				) : null}
			</Table.LinkCell>
			<Table.Cell>{user.email}</Table.Cell>
			<Table.Cell>
				{models.membership.roleToTranslatedString(membershipRole)}
			</Table.Cell>
			<Table.Cell>
				<Row autoColumns="auto">
					{capabilities
						.sort((a, b) => a.localeCompare(b))
						.map(
							(capability) =>
								capabilities.includes(capability) && (
									<Label color={getCapabilityLabelColor(capability)}>
										{models.membership.capabilityToTranslatedString(capability)}
									</Label>
								)
						)}
				</Row>
			</Table.Cell>
			<Table.Cell>{user.birthDate}</Table.Cell>
			<Table.ActionCell>
				<ContextMenu.Menu
					toggleWith={
						<ContextMenu.TableTrigger>
							<Icon name="context-menu" />
						</ContextMenu.TableTrigger>
					}>
					<ContextMenu.LinkItem
						href={routes.Settings.User.Show(organizationId, groupId, userId)}
						key="show-profile">
						<ContextMenu.ItemIcon name="arrow_forward" />
						{t('View user')}
					</ContextMenu.LinkItem>
					<ContextMenu.LinkItem onClick={handleAddToGroup}>
						<ContextMenu.ItemIcon name="group_add" />
						{t('Add to group')}
					</ContextMenu.LinkItem>
					<Fragment>
						<ContextMenu.Divider />
						<ContextMenu.Label>{t('Role')}</ContextMenu.Label>
						{editable_roles.map((role) => (
							<ContextMenu.ControlItem
								onClick={() => handleChangeRole(role)}
								type="radio"
								checked={role === membershipRole}>
								{models.membership.roleToTranslatedString(role)}
							</ContextMenu.ControlItem>
						))}

						<ContextMenu.Divider />
						<ContextMenu.Label>{t('Capabilities')}</ContextMenu.Label>
						{editable_capabilities.map((capability) => (
							<ContextMenu.ControlItem
								onClick={() => handleChangeCapability(capability)}
								type="checkbox"
								checked={capabilities.includes(capability)}>
								{models.membership.capabilityToTranslatedString(capability)}
							</ContextMenu.ControlItem>
						))}

						<ContextMenu.Divider />
					</Fragment>
					<RemoveUserItem dialog={removeUserDialog} />
				</ContextMenu.Menu>
			</Table.ActionCell>
		</Table.Row>
	);
}

interface UserListProps {
	organizationId: number;
	groupId: number;
}

export default function GroupUsersList({
	organizationId,
	groupId,
}: UserListProps): JSX.Element {
	const [showInviteExistingUser, setShowInviteExistingUser] = useState(false);

	const {
		records: memberships,
		isLoading,
		selection,
		selectedRecords,
		refresh,
	} = useCollection<models.membership.Membership>(
		endpoints.Groups.ShowUsers(groupId)
	);

	const openInviteExistingUserModal = () => setShowInviteExistingUser(true);
	const closeInviteExistingUserModal = () => {
		setShowInviteExistingUser(false);
		refresh();
	};

	const [modal, setModal] = useState<{
		show: boolean;
		name: string;
		userId?: number;
	}>({
		show: false,
		name: '',
		userId: 0,
	});

	const handleAddToGroup = async (groupId: number, role: number) => {
		const [request] = await models.create(
			endpoints.UserGroups.AddUsersToGroup(groupId),
			modal.userId
				? [{ id: modal.userId, role }]
				: selection.selectedRecords.map((userId) => ({
						id: userId,
						role,
					}))
		);

		if (request.ok) {
			actions.flashes.show({
				title: t('Added user to group'),
			});
		} else {
			actions.flashes.show({
				title: t('Unable to add user to group'),
			});
		}
	};

	const handleCloseModal = (): void =>
		setModal({ show: false, name: '', userId: 0 });

	const emptyState = {
		title: t('No users found'),
		image: <AssetImage src="img/missing-entities/contacts.svg" />,
	};

	return (
		<Fragment>
			<ActionBar.Bar>
				<Button
					label={t('Add users')}
					variant="primary"
					icon="add"
					onClick={openInviteExistingUserModal}
				/>
			</ActionBar.Bar>
			<MembershipBulkActions
				groupId={groupId}
				selection={selection}
				selectedRecords={selectedRecords}
				onRemove={refresh}
			/>
			<Table.Table
				emptyState={emptyState}
				stickyFooter
				columns={[
					{
						content: (
							<Input.Control
								testid="check-all"
								type="checkbox"
								standalone
								checked={selection.isAllSelected}
								disabled={memberships.length === 0}
								onChange={selection.selectAll}
							/>
						),
						width: 'min-content',
					},
					{
						content: t('Name'),
					},
					{ content: '', width: 'min-content' },
					{
						content: t('Email'),
					},
					{
						content: t('Role'),
					},
					{
						content: t('Capabilities'),
					},
					{
						content: t('Date of birth'),
					},
					{
						content: '',
						width: 'max-content',
					},
				]}
				isLoading={isLoading}>
				{memberships.map((m) => (
					<MembershipRow
						selection={selection}
						membership={m}
						setModal={setModal}
						organizationId={organizationId}
						groupId={groupId}
						refresh={refresh}
					/>
				))}
			</Table.Table>
			{modal.show && modal.name === 'group' && (
				<AddToSingleGroupModal
					groupId={groupId}
					onConfirm={handleAddToGroup}
					onClose={handleCloseModal}
				/>
			)}

			{showInviteExistingUser && (
				<AddUserModal
					groupId={groupId}
					onClose={closeInviteExistingUserModal}
				/>
			)}
		</Fragment>
	);
}
