import { useT } from '@transifex/react';

import * as models from 'pkg/api/models';

import DataList, {
	DataListItem,
} from 'routes/organization/user-profile/data-list';

import CollapsiblePanel from 'components/sidebar/collapsible-panel';

interface UserDetailsProps {
	user: models.user.User;
}

export default function UserDetails({ user }: UserDetailsProps): JSX.Element {
	const t = useT();

	const items: DataListItem[] = [];

	if (user.email) {
		items.push({ label: t('Email'), value: user.email });
	}

	if (user.sex) {
		items.push({
			label: t('Gender'),
			value: models.user.translatedSexString(user.sex),
		});
	}

	if (user.birthDate) {
		items.push({
			label: t('Birth date'),
			value: user.birthDate,
		});
	}

	if (user.phoneNumber) {
		items.push({ label: t('Phone number'), value: user.phoneNumber });
	}

	if (user.address && user.postalCode && user.city) {
		const addr = (
			<div>
				<p>{user.address}</p>
				<p>
					{user.postalCode} {user.city}
				</p>
				{user.region && <p>{user.region}</p>}
			</div>
		);

		items.push({
			label: t('Address'),
			value: addr,
		});
	}

	if (user.country) {
		items.push({
			label: t('Country'),
			value: models.user.getCountryString(user),
		});
	}

	if (items.length === 0) {
		return null;
	}

	return (
		<CollapsiblePanel heading={t('Details')}>
			<DataList items={items} />
		</CollapsiblePanel>
	);
}
