export function AddUserAttachment(
	userID: number,
	attachmentID: number,
	groupID: number
): string {
	return `/v1/users/${userID}/attachments/${attachmentID}/${groupID}`;
}

export function AttendanceStats(userID: number): string {
	return `/v1/users/${userID}/stats/attendance`;
}

export function ByExternalSource(): string {
	return `/v1/users/with-videosource`;
}

export function ConnectWithExternalAccount(userID: number): string {
	return `/v1/users/${userID}/external-services/connect`;
}

export function Create(): string {
	return `/v1/users`;
}

export function DefaultPaymentSource(userID: number): string {
	return `/v1/users/${userID}/default-payment-source`;
}

export function Delete(): string {
	return `/v1/users`;
}

export function DeleteExternalConnection(connectionID: number): string {
	return `/v1/external-services/connections/${connectionID}`;
}

export function DeregisterPushNotificationDevice(ID: number): string {
	return `/v1/me/pns/${ID}`;
}

export function ListActivePaymentMethods(userID: number): string {
	return `/v1/users/${userID}/payment-methods/active`;
}

export function ListExternalConnections(userID: number): string {
	return `/v1/users/${userID}/external-services/connections`;
}

export function ListPaymentMethods(userID: number): string {
	return `/v1/users/${userID}/payment-methods`;
}

export function ListRelatives(userID: number): string {
	return `/v1/users/${userID}/relatives`;
}

export function ListUserAttachments(userID: number, groupID: number): string {
	return `/v1/users/${userID}/attachments/${groupID}`;
}

export function ListVideos(userID: number): string {
	return `/v1/users/${userID}/videos`;
}

export function Merge(userID: number, sourceUserID: number): string {
	return `/v1/users/${userID}/merge/${sourceUserID}`;
}

export function PhysicalStrainStats(userID: number): string {
	return `/v1/users/${userID}/stats/physical-strain`;
}

export function RegisterPushNotificationDevice(): string {
	return `/v1/me/pns`;
}

export function RemoveUserAttachment(
	userID: number,
	attachmentID: number
): string {
	return `/v1/users/${userID}/attachments/${attachmentID}`;
}

export function SetBillingUserToLegalGuardian(): string {
	return `/v1/users/set-billing-user-to-legal-guardian`;
}

export function Show(userID: number): string {
	return `/v1/users/${userID}`;
}

export function ShowLoginInformation(userID: number): string {
	return `/v1/users/${userID}/login-information`;
}

export function Undelete(userID: number): string {
	return `/v1/users/${userID}/undelete`;
}

export function Update(userID: number): string {
	return `/v1/users/${userID}`;
}
