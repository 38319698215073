import { useFormContext } from 'react-hook-form';
import {
	ChangeEvent,
	Fragment,
	useContext,
	useEffect,
	useRef,
	useState,
} from 'react';
import { t } from '@transifex/native';

import { Statuses } from 'pkg/models/form';

import { FormCategoryPayload } from 'pkg/actions/services/form_categories';

import { Field } from 'pkg/api/models/form';
import { FormCategory } from 'pkg/api/models/form_category';
import * as actions from 'pkg/actions';
import * as models from 'pkg/api/models';
import * as endpoints from 'pkg/api/endpoints/auto';
import { useCollection } from 'pkg/api/use_collection';
import { useCurrentGroupId } from 'pkg/identity';

import { FormEngineContext } from 'routes/forms/hooks/useFormEngine';
import FormsAttachments from 'routes/forms/hooks/sections/attachments';
import { CategoryModal } from 'routes/forms/hooks/modals';

import File from 'components/form/File';
import * as Input from 'components/form/inputs';
import Column from 'components/layout/column';
import Section from 'components/form/Section';

import Button from 'design/button';

interface GeneralProps {
	onGeneralFieldChange: (field: string, value: string) => void;
	data: {
		title: string;
		formCategoryId: number;
		description: string;
		attachment: models.attachment.Attachment;
		formId: number;
		fields: Field[];
		status: Statuses;
	};
}

const General: React.FC<React.PropsWithChildren<GeneralProps>> = ({
	data,
	onGeneralFieldChange,
}) => {
	const [modal, setModal] = useState('');

	const ref = useRef(null);
	const formEngineContext = useContext(FormEngineContext);
	const groupId = useCurrentGroupId();

	const {
		records: categories,
		refresh: refreshCategories,
		isLoading: isLoadingCategories,
	} = useCollection<models.formCategory.FormCategory>(
		endpoints.FormCategories.Index(),
		{
			queryParams: new URLSearchParams({ group_id: groupId.toString() }),
		}
	);

	const NEW_CATEGORY = 'new_category';

	const { watch } = useFormContext();
	const categorySelect = watch('formCategoryId');

	useEffect(() => {
		if (categorySelect === NEW_CATEGORY) {
			setModal('new_category');
		}
	}, [categorySelect]);

	const handleNew = async (files: FileList) => {
		const file = files[0];

		const fileType = file.type.startsWith('image/')
			? models.attachment.AttachmentType.Image
			: models.attachment.AttachmentType.File;

		const attachment = await actions.attachments.upload(
			{
				type: fileType,
				visibility: models.attachment.AttachmentVisibility.Visible,
				title: file.name,
				originalFilename: file.name,
				file,
			},
			{
				onProgress: (uploaded, total, attachment) => {
					formEngineContext.setFormState({
						attachment: { ...attachment, bytesUploaded: uploaded },
					});
				},
			}
		);

		if (attachment === null) {
			return;
		}

		formEngineContext.setFormState({
			attachment,
		});

		if (data.formId) {
			await actions.forms.update(data.formId, {
				status: data.status,
				fields: data.fields as any,
				title: data.title,
				attachmentId: attachment.id,
			});
		}
	};

	const handleDelete = async () => {
		models.attachment.destroy(formEngineContext.formState.attachment);

		formEngineContext.setFormState({
			attachment: {} as models.attachment.Attachment,
		});

		if (data.formId) {
			await actions.forms.update(data.formId, {
				status: data.status,
				fields: data.fields as any,
				title: data.title,
				attachmentId: null,
			});
		}
	};

	const handleCreateCategory = async (
		category: FormCategoryPayload,
		postCreate: (formCategoryId: number) => void
	) => {
		const result = await actions.formCategories.create({
			...category,
			groupId,
		});

		await refreshCategories();

		formEngineContext.setFormState({
			formCategoryId: result.id,
		});
		postCreate(result.id);
	};

	const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
		onGeneralFieldChange(event.target.name, event.target.value);
	};

	const handleAttachmentClick = () => ref.current.click();

	const handleCloseModal = () => {
		setModal('');
	};

	return (
		<Fragment>
			<Section
				icon="info"
				title={t('{step}. General', {
					step: 1,
				})}
				description={t(
					'This is the general description for the registration.'
				)}>
				<Column>
					<Input.Group
						label={t('Title')}
						hint={t('Title of your form, e.g Club Registration Form.')}>
						<Input.Field
							name="title"
							required
							testid="form.create.title"
							value={data.title}
							onChange={handleFieldChange}
						/>
					</Input.Group>

					{isLoadingCategories ? null : (
						<Input.Group label={t('Category')} optional>
							<Input.Select
								name="formCategoryId"
								defaultValue={data.formCategoryId}>
								<option value="">{t('Select category')}</option>
								<option value={NEW_CATEGORY}>{t('Create new category')}</option>
								{categories.map((category: FormCategory) => (
									<option key={category.id} value={category.id}>
										{category.title}
									</option>
								))}
							</Input.Select>
						</Input.Group>
					)}

					<Input.Group label={t('Description')} optional>
						<Input.Area
							name="description"
							minRows={4}
							testid="form.create.description"
							value={data.description}
							onChange={handleFieldChange}
						/>
					</Input.Group>
					{Object.keys(data.attachment).length > 0 ? (
						<FormsAttachments
							attachments={[data.attachment]}
							onDelete={handleDelete}
						/>
					) : (
						<File
							inputRef={ref}
							multiple={false}
							onChange={handleNew}
							accept="image/*">
							<Input.Group label={t('Cover image')}>
								<Button onClick={handleAttachmentClick} secondary icon="image">
									{t('Upload image')}
								</Button>
							</Input.Group>
						</File>
					)}
				</Column>
			</Section>

			{modal === 'new_category' && (
				<CategoryModal
					onDone={handleCreateCategory}
					onClose={handleCloseModal}
				/>
			)}
		</Fragment>
	);
};

export default General;
